import React, {useCallback} from 'react';
import {CBCButton} from 'shared/helpers';
import {Modal} from 'react-bootstrap';
import {CloseEventType} from 'components/customer/AdvancedMaterials/Footer';
import styled from 'styled-components';
import {PreviewLocation} from 'Preview3D/types';
import {Preview3DComponent} from 'components/customer/Preview3D/components/Preview3DComponent';

interface ExpandedViewProps {
    onClose?: () => void;
    onCancel?: () => void;
    className?: string;
    $width?: number;
    previewLocation: PreviewLocation;
    open: boolean;
    setShowExpandedView: (show: boolean) => void;
}

const ExpandedView = ({
    onCancel,
    previewLocation,
    open,
    setShowExpandedView,
}: ExpandedViewProps) => {
    const handleClose = useCallback(
        (event: CloseEventType) => () => {
            if (
                event == CloseEventType.CANCEL &&
                typeof onCancel == 'function'
            ) {
                onCancel();
                setShowExpandedView(false);
            } else {
                setShowExpandedView(false);
            }
        },
        [onCancel, open]
    );

    return (
        <ExpandedViewModal
            show={open}
            onHide={handleClose(CloseEventType.CANCEL)}
            animation={false}>
            <Header>
                <div>Product Visualiser</div>
                <CloseButton
                    iconName="Button-Cancel.svg"
                    onClick={handleClose(CloseEventType.CANCEL)}
                    className="job-button button-light"
                    style={{color: '#8A8080'}}
                />
            </Header>
            <Body>
                <Container>
                    <Preview3DComponent
                        previewLocation={previewLocation}
                        expanded
                    />
                </Container>
            </Body>
        </ExpandedViewModal>
    );
};

const ExpandedViewModal = styled(Modal)`
    background: rgba(255, 255, 255, 0.5);

    .modal-dialog {
        width: 50vw;
        max-width: initial;
        height: calc(100% - 200px);
        margin: 100px auto;
    }

    .modal-content {
        border-radius: 20px;
        box-shadow: 0px 0px 10px 1px #616161bf;
        border: 0;
        height: 100%;
    }

    @media screen and (max-height: 768px) and (orientation: landscape),
        screen and (max-width: 1367px) and (orientation: landscape) {
        .modal-dialog {
            width: 70vw;
            margin: 0;
            height: calc(100% - 50px);
            margin: 25px auto;
        }
    }

    @media screen and (max-width: 1024px) and (orientation: portrait) {
        .modal-dialog {
            width: 90vw;
            height: calc(100% - 300px);
            margin: 150px auto;
        }
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        overflow-y: hidden !important;

        .modal-dialog {
            width: 100vw;
            height: 100vh;
            margin: 0;
        }

        .modal-content {
            border-radius: 0 !important;
            height: 100%;
        }
    }
`;

export const Header = styled(Modal.Header)`
    background: rgb(var(--primary_colour));
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 10px;

    > div {
        text-align: center;
        width: 100%;
        font-weight: 900;
        color: white;
        font-size: 1em;
    }

    .close {
        display: none;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        border-radius: 0 !important;
    }
`;

const Container = styled.div`
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        padding: 10px;
    }
`;

const Body = styled(Modal.Body)`
    padding: 0;
    display: flex;
    height: calc(100% - 45px - 71px);
`;

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
export const CloseButton = styled((props) => <CBCButton {...props} />)`
    color: rgb(138, 128, 128);
    background: transparent;
    border: 0 !important;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;

    img {
        width: 20px !important;
        margin: 0 !important;
    }
`;

export default ExpandedView;
