import {useCallback} from 'react';
import {useAppSelector} from 'store/customer';
import {useProductContext} from 'contexts';
import {
    TemplateVariable,
    ProductDataStore,
} from 'components/customer/Preview3D/usePreview3DData';
import {ConditionalValues, Drawer} from 'Preview3D/types';
import {makeZeroIfUndefined} from 'components/customer/Preview3D/helpers';
import {getBaseInnerDrawerFaceHeight} from 'components/customer/Preview3DCommon/store/viewerSlice';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

type FormValues = {
    height: number;
    depth: number;
    drawerGap: number;
    drawers: Drawer[];
    drawerTop: number;
    drawerBottom: number;
    drawerLeft: number;
    drawerRight: number;
    ovenOpeningHeight: number;
    microwaveOpeningHeight: number;
    width: number;
    hasDoor: boolean;
    lowerShelfHeight: number;
    drawerFaceHeights: number[];
    toeKickHeight: number;
};

export type DrawerValues = {
    faceHeight: number;
    depth: number;
    position: number;
    bottomPosition: number;
    bottomYPosition: number;
    drawerBottomYPosition: number;
    bottomDepth: number;
    depthOffset: number;
    index: number;
    drawerBackHeight: number;
    isInsetDrawer: boolean;
};

const NONE_DRAWER_TYPE = 82;

const useDrawerDynamicValues = () => {
    const {values} = usePreviewFormValues();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {productDataStore} = useProductContext() as {
        productDataStore: ProductDataStore;
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const isPantry = variables?.shelfType === 'PANTRY';
    const innerDrawerFaceHeight = useAppSelector(getBaseInnerDrawerFaceHeight);
    const hasToeKick = Boolean(variables.hasToeKick);
    const hasInsetDrawer = Boolean(variables.hasInsetDrawer);
    const isMicrowaveDrawer =
        typeof values.microwave_opening_height !== 'undefined' &&
        !variables?.isWallOvenProduct;
    const {exteriorThickness, carcaseThickness} = useMaterialTexture();

    const formValues: FormValues = {
        height: makeZeroIfUndefined(values.cabinet_height),
        width: makeZeroIfUndefined(values.cabinet_width),
        depth: makeZeroIfUndefined(values.cabinet_depth),
        drawerGap: makeZeroIfUndefined(values.cabinet_drawer_gap),
        drawers: values.drawers as unknown as Drawer[],
        drawerTop: makeZeroIfUndefined(values.cabinet_drawer_top),
        drawerBottom: makeZeroIfUndefined(values.cabinet_drawer_bottom),
        drawerLeft: makeZeroIfUndefined(values.cabinet_drawer_left),
        drawerRight: makeZeroIfUndefined(values.cabinet_drawer_right),
        ovenOpeningHeight: makeZeroIfUndefined(values.oven_opening_height),
        microwaveOpeningHeight: makeZeroIfUndefined(
            values.microwave_opening_height
        ),
        lowerShelfHeight: makeZeroIfUndefined(values.cabinet_partition_height),
        hasDoor: typeof values.cabinet_door_gap !== 'undefined',
        drawerFaceHeights: values.drawer_face_height
            ? values.drawer_face_height
            : null,
        toeKickHeight: makeZeroIfUndefined(values.cabinet_toekick),
    };

    const sumProperty = (array: Drawer[], property: keyof Drawer) => {
        return array.reduce(
            // eslint-disable-next-line security/detect-object-injection
            (acc, obj) => acc + parseFloat(obj[property]?.toString()),
            0
        );
    };

    const generateDrawerPosition = useCallback(() => {
        return generateDrawers(
            formValues.drawers,
            false,
            variables?.isWallOvenProduct
                ? Number(values.cabinet_total_drawer_height)
                : formValues.height
        );
    }, [formValues]);

    const generateDrawers = useCallback(
        (
            drawers: Drawer[],
            isInnerDrawer = false,
            containerHeight = formValues.height,
            containerWidth = formValues.width
        ) => {
            const drawerValues: DrawerValues[] = [];
            const drawerLength = drawers?.length;

            for (let index = 0; index < drawerLength; index++) {
                let faceHeight =
                    drawers && drawers[Number(index)]
                        ? drawers[Number(index)].drawer_face_height
                        : 0;

                if (
                    Boolean(formValues.microwaveOpeningHeight) &&
                    !variables?.isWallOvenProduct
                ) {
                    faceHeight =
                        containerHeight -
                        formValues.microwaveOpeningHeight -
                        formValues.drawerTop -
                        formValues.drawerBottom -
                        1;
                }

                const slicedDrawer = drawers.slice(index + 1);
                const gapCount = slicedDrawer?.length;

                const totalHeight = sumProperty(
                    slicedDrawer,
                    'drawer_face_height'
                );

                let position = 0;
                let unshift = false;
                const toeKickHeight = hasToeKick
                    ? formValues.toeKickHeight + carcaseThickness
                    : 0;
                const isInsetDrawer = hasInsetDrawer;

                if (isInnerDrawer) {
                    let topDrawerHeightTotal = faceHeight * drawerLength;

                    if (isPantry) {
                        topDrawerHeightTotal =
                            faceHeight * (drawerLength - 1) + 50;
                    }

                    const totalGapSpace =
                        containerHeight - topDrawerHeightTotal;
                    const gap = totalGapSpace / (drawerLength + 1);

                    if (drawerLength === index + 1)
                        position = formValues.drawerBottom + exteriorThickness;
                    else {
                        unshift = true;

                        position =
                            gap * (index + 1) +
                            faceHeight * index +
                            faceHeight +
                            gap / 2;
                    }
                } else {
                    if (gapCount > 0) {
                        position =
                            totalHeight +
                            parseFloat(formValues.drawerGap?.toString()) *
                                gapCount +
                            parseFloat(formValues.drawerBottom?.toString()) +
                            toeKickHeight -
                            (isInsetDrawer ? carcaseThickness : 0);
                    } else {
                        position =
                            formValues.drawerBottom +
                            toeKickHeight -
                            (isInsetDrawer ? carcaseThickness : 0);
                    }

                    if (formValues.hasDoor) {
                        position =
                            containerHeight - faceHeight - formValues.drawerTop;
                    }
                }

                const regex = /(\d+)x(\d+)/;

                const match =
                    drawers &&
                    drawers[Number(index)] &&
                    drawers[Number(index)].drawer_runner_specs &&
                    parseInt(drawers[Number(index)].drawer_runner_specs) != -1
                        ? drawers[Number(index)]?.drawer_runner_specs?.match(
                              regex
                          )
                        : ['500', '500'];

                const depth = match ? parseFloat(match[2]) : formValues.depth;

                const depthOffset =
                    isInsetDrawer || isMicrowaveDrawer ? exteriorThickness : 0;

                let bottomPosition =
                    drawerLength == index + 1 &&
                    !formValues.hasDoor &&
                    !isInnerDrawer &&
                    !isInsetDrawer &&
                    !isMicrowaveDrawer
                        ? (formValues.drawerBottom > 0
                              ? formValues.drawerBottom
                              : 0) + toeKickHeight
                        : position;

                if (variables?.isWallOvenProduct && drawerLength == index + 1) {
                    bottomPosition = position = formValues.drawerBottom;
                }

                let drawerBackHeight = match
                    ? parseFloat(match[1]) - exteriorThickness
                    : 150;

                if (variables?.isBinProduct) {
                    const binDrawerRunnerHeight = 150;
                    drawerBackHeight = binDrawerRunnerHeight;
                    bottomPosition =
                        containerHeight - binDrawerRunnerHeight * 2;
                }

                const onlyShowDrawerFace = drawers[Number(index)].drawer_type
                    ? drawers[Number(index)].drawer_type === NONE_DRAWER_TYPE
                    : false;

                const drawerItem = {
                    faceHeight,
                    depth,
                    position,
                    bottomPosition:
                        bottomPosition + (isInnerDrawer ? 0 : carcaseThickness),
                    index,
                    bottomYPosition: depth + depthOffset,
                    drawerBottomYPosition:
                        depth - carcaseThickness + depthOffset,
                    bottomDepth: depth - carcaseThickness,
                    depthOffset,
                    drawerBackHeight,
                    onlyShowDrawerFace,
                    isInsetDrawer,
                };

                if (unshift) drawerValues.unshift(drawerItem);
                else drawerValues.push(drawerItem);
            }

            const drawerWidth = containerWidth - 100;
            const sideOffset = 54 + carcaseThickness;
            const backHorizontalPosition = containerWidth - 100 + sideOffset;
            const drawerFaceWidth =
                containerWidth +
                carcaseThickness -
                formValues.drawerLeft -
                formValues.drawerRight -
                (isInnerDrawer ? carcaseThickness * 2 : 0);
            const drawerHorizontalPosition =
                formValues.drawerLeft + (isInnerDrawer ? carcaseThickness : 0);

            return {
                drawers: drawerValues,
                drawerFaceWidth,
                drawerHorizontalPosition,
                ...(hasInsetDrawer
                    ? {
                          drawerInsetFaceWidth:
                              drawerFaceWidth - carcaseThickness * 2,
                          drawerInsetHorizontalPosition:
                              drawerHorizontalPosition + carcaseThickness,
                      }
                    : {}),
                drawerWidth,
                backHorizontalPosition,
                runnerLeftHorizontalPosition: sideOffset,
                drawerWidthWithOffset: drawerWidth - carcaseThickness,
                backHorizontalPositionWithOffset:
                    backHorizontalPosition - carcaseThickness,
                ovenPosition: containerHeight - formValues.ovenOpeningHeight,
                microwavePosition:
                    containerHeight - formValues.microwaveOpeningHeight,
            };
        },
        [formValues]
    );

    const cloneObjectNTimes = <T>(obj: T, n: number): T[] =>
        Array.from({length: n}, () => ({...obj}));

    const generateInnerDrawer = () => {
        if (!variables.innerDrawerCount) return;
        const faceHeight =
            innerDrawerFaceHeight !== 0 ? innerDrawerFaceHeight : 200;
        const shallowFaceHeight =
            innerDrawerFaceHeight !== 0 ? innerDrawerFaceHeight : 120;

        const innerDrawer = {
            drawer_face_height: faceHeight,
            drawer_runner_specs: `${faceHeight}x${
                formValues.depth - 100
            } : White D`,
            drawer_type: 1,
        };

        const innerDrawers = cloneObjectNTimes(
            innerDrawer,
            variables.innerDrawerCount
        );

        const result = generateDrawers(
            isPantry
                ? innerDrawers.map((drawer, index) => {
                      if (index === innerDrawers?.length - 2) {
                          drawer.drawer_runner_specs = `${shallowFaceHeight}x${
                              formValues.depth - 100
                          } : White D`;
                      }

                      return drawer;
                  })
                : innerDrawers,
            true,
            variables.hasLowerShelf
                ? formValues.lowerShelfHeight
                : formValues.height
        );

        return result;
    };

    const generateDrawerFace = () => {
        if (!formValues.drawerFaceHeights) return;

        const drawers = formValues.drawerFaceHeights.map((faceHeight) => ({
            drawer_face_height: faceHeight,
            drawer_runner_specs: `199x500 : White D`,
            drawer_type: 1,
        }));

        const result = generateDrawers(
            drawers,
            true,
            values.cabinet_panel_length,
            values.cabinet_panel_width
        );

        return result;
    };

    const drawerValues: ConditionalValues[] = [
        {
            key: 'drawerDefault',
            condition: Boolean(formValues.drawers),
            callback: generateDrawerPosition,
        },
        {
            key: 'innerDrawers',
            condition: Boolean(variables.innerDrawerCount),
            callback: generateInnerDrawer,
        },
        {
            key: 'drawerFace',
            condition: Boolean(formValues.drawerFaceHeights),
            callback: generateDrawerFace,
        },
    ];

    return {
        drawerValues,
    };
};

export default useDrawerDynamicValues;
