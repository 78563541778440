import React, {useMemo, useCallback, useRef, useEffect} from 'react';
import {useNotificationContext, useProductContext} from 'contexts';
import Excel from 'shared/Excel';
import useDynamicValues from 'components/customer/Preview3D/values/useDynamicValues';
import nunjucks from 'nunjucks';
import {ShelfType, PreviewFormValues, PreviewLocation} from 'Preview3D/types';
import useAdjustableLegs from 'components/customer/Preview3D/hardware/useAdjustableLegs';
import useInnerDrawer from 'components/customer/Preview3D/hardware/useInnerDrawer';
import useShapedPanel from 'components/customer/Preview3D/hardware/useShapedPanel';
import * as THREE from 'three';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

export type TemplateVariable = {
    fields: {
        name: string;
        expression: string;
    }[];
    shelfType?: ShelfType;
    isLeftReturn?: boolean;
    innerDrawerCount?: number;
    hasLowerShelf?: boolean;
    isBroom2Part?: boolean;
    hasToeKick?: boolean;
    noInteractions?: boolean;
    hasLShapedShelves?: boolean;
    isReturnProduct?: boolean;
    isLShapePanel?: boolean;
    isBinProduct?: boolean;
    hasRangehoodVent?: boolean;
    hasInsetDrawer?: boolean;
    isCornerProduct?: boolean;
    isUShapedPanel?: boolean;
    hasCornerShapedShelves?: boolean;
    noCornerBaseBottom?: boolean;
    is2Part?: boolean;
    isUpperProduct?: boolean;
    isClipProduct?: boolean;
    isLeftClip?: boolean;
    isRadiusProduct?: boolean;
    isLeftRadius?: boolean;
    isWallOvenProduct?: boolean;
    isLShapedPantry?: boolean;
    isApplianceBifoldProduct?: boolean;
    isApplianceLeftBifoldRight?: boolean;
    isApplianceLeftRightBifold?: boolean;
    isApplianceBifoldPair?: boolean;
    isApplianceProduct?: boolean;
    isOpenLShape?: boolean;
    is3DoorProduct?: boolean;
};

export type ProductDataStore = React.MutableRefObject<{
    template_3d: {attributes: {variables: string; template: string}}[];
}>;

type DynamicValues<T> = Record<string, string | number | boolean | T>;

const usePreview3DData = (
    scene: React.MutableRefObject<THREE.Scene>,
    toggleInnerDrawer: (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        innerDrawerYPos: number,
        depthOffsetCalculation: number
    ) => void,
    showTexture?: boolean,
    previewLocation?: PreviewLocation
) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {productDataStore} = useProductContext() as {
        productDataStore: ProductDataStore;
    };
    const {values} = usePreviewFormValues();
    const {messages} = useNotificationContext();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );
    const templateData: string =
        productDataStore.current?.template_3d[0].attributes?.template;

    const {adjustableLegsParts} = useAdjustableLegs(
        scene,
        variables.isLeftReturn,
        variables.hasLShapedShelves || variables.isCornerProduct
    );

    const {exteriorThickness, carcaseThickness} = useMaterialTexture();
    const hasError = useRef(false);

    const fieldDependencies = useMemo(() => {
        const fieldNames = variables.fields
            .map((f) => {
                if (values.hasOwnProperty(f.expression)) return f.expression;

                return '';
            })
            .filter((f) => f);

        return [
            ...fieldNames.map((field) => {
                return {
                    [String(field)]: values[String(field)] as PreviewFormValues,
                };
            }),
        ];
    }, [values]);

    const {dynamicValues} = useDynamicValues(variables?.shelfType, [
        ...fieldDependencies,
        messages.errors,
    ]);

    const {innerDrawerParts} = useInnerDrawer(
        scene,
        toggleInnerDrawer,
        dynamicValues,
        showTexture
    );

    const {shapedParts} = useShapedPanel(scene, showTexture, previewLocation);

    const replaceVariables = (
        jsonString: string,
        replacements: DynamicValues<
            (typeof dynamicValues)[keyof typeof dynamicValues]
        >
    ): string => {
        const renderedTemplate = nunjucks.renderString(
            jsonString,
            replacements
        );

        return renderedTemplate;
    };

    const replaceDimensions = useCallback(
        (json: string) => {
            const formValues: DynamicValues<
                (typeof dynamicValues)[keyof typeof dynamicValues]
            > = {
                ...dynamicValues,
            };

            variables.fields.forEach((f) => {
                const evaluated = Excel.calculate(f.expression, {
                    ...values,
                    exteriorThickness,
                    carcaseThickness,
                });
                formValues[f.name] = evaluated;
            });

            return replaceVariables(json, {...formValues});
        },
        [...fieldDependencies, messages.errors]
    );

    const setupHardware = () => {
        const parts = [...innerDrawerParts, ...adjustableLegsParts];

        for (let index = 0; index < parts.length; index++) {
            const {condition, callback} = parts[Number(index)];

            if (condition) callback();
        }
    };

    const getShapedPanels = () => {
        const parts = [...shapedParts];

        const meshes: THREE.Mesh[] = [];

        for (let index = 0; index < parts.length; index++) {
            const {condition, callback} = parts[Number(index)];

            if (condition) {
                const result = callback();

                if (result) {
                    meshes.push(...result);
                }
            }
        }

        return meshes;
    };

    useEffect(() => {
        hasError.current =
            messages.errors &&
            Boolean((messages.errors as {fields: string[]}[])?.length);
    }, [messages.errors]);

    return {
        variables,
        fieldDependencies,
        replaceVariables,
        replaceDimensions,
        templateData,
        setupHardware,
        hasError,
        errors: messages.errors,
        getShapedPanels,
    };
};

export default usePreview3DData;
