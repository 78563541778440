import React, {useRef} from 'react';
import * as THREE from 'three';

const useRendererSetup = (
    previewRef: React.MutableRefObject<HTMLDivElement>
) => {
    const renderer = useRef<THREE.WebGLRenderer>();

    const setupRenderer = () => {
        const dimension = previewRef.current.getBoundingClientRect();

        renderer.current = new THREE.WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: true,
        });
        renderer.current.setPixelRatio(window.devicePixelRatio);
        renderer.current.setSize(dimension.width, dimension.height);
        renderer.current.setClearColor('#fff', 1);
        renderer.current.autoClear = false;
        renderer.current.domElement.style.borderRadius = '8px 0 0 8px';

        return renderer;
    };

    return {
        setupRenderer,
        renderer,
    };
};

export default useRendererSetup;
