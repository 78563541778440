import {useCallback} from 'react';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

const useDefaultDynamicValues = () => {
    const SIDE_PANEL_OFFSET_Z = 1;
    const {values} = usePreviewFormValues();

    const formValues = {
        depth: Number(values.cabinet_depth),
        width: Number(values.cabinet_width),
        cabinetTop: Number(values.cabinet_top),
        height: Number(values.cabinet_height),
    };
    const {exteriorThickness, carcaseThickness} = useMaterialTexture();

    const generateDefaultValues = useCallback(() => {
        return {
            baseBottomDepth: formValues.depth - carcaseThickness,
            baseBottomWidth: formValues.width - carcaseThickness,
            baseBackDepth: formValues.depth - carcaseThickness,
            baseBackWidth: formValues.width - carcaseThickness,
            baseFrontWidth: formValues.width + carcaseThickness,
            baseBottomDepthExt: formValues.depth - exteriorThickness,
            baseBackWidthExt: formValues.width - exteriorThickness,
            // To fix the misalignment of base side panels
            baseSideHeight: formValues.height + SIDE_PANEL_OFFSET_Z,
            sidePanelOffsetZ: SIDE_PANEL_OFFSET_Z,
            exteriorThickness,
            carcaseThickness,
            isTimberRailOnEdge: formValues.cabinetTop == 175,
        };
    }, [formValues, exteriorThickness, carcaseThickness]);

    return {
        generateDefaultValues,
    };
};

export default useDefaultDynamicValues;
