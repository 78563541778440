import React, {useCallback, useRef, useMemo, useEffect, useState} from 'react';
import * as THREE from 'three';
import {useAppDispatch, useAppSelector} from 'store/customer';
import TWEEN from '@tweenjs/tween.js';
import {PreviewPosition} from 'Preview3D/types';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {debounce} from 'lodash';
import {
    setIsPreviewHighlighted,
    setExpandedViewCameraPosition,
    getExpandedViewCameraPosition,
} from 'components/customer/Preview3DCommon/store/viewerSlice';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';
import {shallowEqual} from 'react-redux';

const useCameraSetup = (
    previewRef: React.MutableRefObject<HTMLDivElement>,
    renderer: React.MutableRefObject<THREE.WebGLRenderer>,
    expanded?: boolean
) => {
    const dispatch = useAppDispatch();
    const camera = useRef<THREE.OrthographicCamera>();
    const controls = useRef<OrbitControls>();
    const expandedViewInitialLoad = useRef(false);
    const {values} = usePreviewFormValues();
    const expandedViewCameraPosition = useAppSelector(
        getExpandedViewCameraPosition,
        shallowEqual
    );

    const useTallCamera = useMemo(() => {
        return Number(values.cabinet_height) > 1100;
    }, [values.cabinet_height]);

    const tallZoom = useMemo(() => {
        return 1250 / Number(values.cabinet_height);
    }, [values.cabinet_height]);

    const tallControlTarget = {
        x: 0,
        y: 0,
        z: Number(values.cabinet_height) / 2 - 500,
    };

    const [firstCameraChange, setFirstCameraChange] = useState(false);

    const setupCamera = useCallback(() => {
        if (previewRef.current) {
            let dimension = previewRef.current.getBoundingClientRect();

            if (dimension?.x == 0) {
                dimension = new DOMRect(202, 492, 43, 360);
            }

            const aspect = dimension.width / dimension.height;
            const frustumSize = 1500;

            const left = (-frustumSize * aspect) / 2;
            const right = (frustumSize * aspect) / 2;
            const top = frustumSize / 1.2;
            const bottom = -frustumSize / 2.8;

            if (camera.current !== undefined) {
                camera.current.left = left;
                camera.current.right = right;
                camera.current.top = top;
                camera.current.bottom = bottom;
            } else {
                camera.current = new THREE.OrthographicCamera(
                    left,
                    right,
                    top,
                    bottom,
                    1,
                    20000
                );
            }

            camera.current.updateProjectionMatrix();

            return camera;
        }
    }, []);

    const setupCameraPosition = (position: PreviewPosition) => {
        const cameraPosition = {x: 0, y: 0, z: 0} as {
            x: number;
            y: number;
            z: number;
        };

        switch (position) {
            case 'RIGHT':
                cameraPosition.x = 2000;
                cameraPosition.y = -3000;
                cameraPosition.z = 2000;
                break;
            case 'LEFT_LEVELED':
                cameraPosition.x = -4100;
                cameraPosition.y = 0;
                cameraPosition.z = controls.current
                    ? controls.current.target.z
                    : 0;
                break;
            case 'FRONT':
                cameraPosition.x = 1;
                cameraPosition.y = -4000;
                cameraPosition.z = controls.current
                    ? controls.current.target.z
                    : 0;
                break;
            case 'RIGHT_LEVELED':
                cameraPosition.x = 4100;
                cameraPosition.y = 0;
                cameraPosition.z = controls.current
                    ? controls.current.target.z
                    : 0;
                break;
            default:
                cameraPosition.x = -2000;
                cameraPosition.y = -3000;
                cameraPosition.z = 2000;
                break;
        }

        camera.current.up.set(0, 0, 1);

        new TWEEN.Tween(camera.current.position)
            .to(
                {
                    x: cameraPosition.x,
                    y: cameraPosition.y,
                    z: cameraPosition.z,
                },
                500
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        new TWEEN.Tween({zoom: camera.current.zoom})
            .to(
                {
                    zoom: useTallCamera ? tallZoom : 1,
                },
                500
            )
            .onUpdate(function ({zoom}) {
                camera.current.zoom = zoom;
                camera.current.updateProjectionMatrix();
            })
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        if (controls.current)
            new TWEEN.Tween(controls.current.target)
                .to(useTallCamera ? tallControlTarget : {x: 0, y: 0, z: 0}, 500)
                .easing(TWEEN.Easing.Cubic.Out)
                .start();

        if (expanded && !expandedViewInitialLoad.current) {
            expandedViewInitialLoad.current = true;

            new TWEEN.Tween(camera.current.position)
                .to(
                    {
                        x: expandedViewCameraPosition.x,
                        y: expandedViewCameraPosition.y,
                        z: expandedViewCameraPosition.z,
                    },
                    500
                )
                .easing(TWEEN.Easing.Cubic.Out)
                .start();

            new TWEEN.Tween({zoom: camera.current.zoom})
                .to(
                    {
                        zoom: expandedViewCameraPosition.zoom,
                    },
                    500
                )
                .onUpdate(function ({zoom}) {
                    camera.current.zoom = zoom;
                    camera.current.updateProjectionMatrix();
                })
                .easing(TWEEN.Easing.Cubic.Out)
                .start();
        }
    };

    const setupOrbitControls = () => {
        controls.current = new OrbitControls(
            camera.current,
            renderer.current.domElement
        );

        if (useTallCamera)
            controls.current.target = new THREE.Vector3(
                tallControlTarget.x,
                tallControlTarget.y,
                tallControlTarget.z
            );

        controls.current.screenSpacePanning = true;
    };

    useEffect(() => {
        const handleControlsChange = debounce(() => {
            if (firstCameraChange) dispatch(setIsPreviewHighlighted(false));
            else setFirstCameraChange(true);
        }, 300);

        controls.current?.addEventListener('change', handleControlsChange);

        return () => {
            controls.current?.removeEventListener(
                'change',
                handleControlsChange
            );
        };
    }, [firstCameraChange]);

    useEffect(() => {
        const handleControlsChange = debounce(() => {
            if (!expanded)
                dispatch(
                    setExpandedViewCameraPosition({
                        x: camera.current?.position.x,
                        y: camera.current?.position.y,
                        z: camera.current?.position.z,
                        zoom: camera.current?.zoom,
                    })
                );
        }, 100);

        controls.current?.addEventListener('change', handleControlsChange);

        return () => {
            controls.current?.removeEventListener(
                'change',
                handleControlsChange
            );
        };
    }, [expanded]);

    return {
        camera,
        setupCamera,
        setupCameraPosition,
        useTallCamera,
        tallControlTarget,
        controls,
        setupOrbitControls,
    };
};

export default useCameraSetup;
