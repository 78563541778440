import React, {ReactNode} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {setHasWebGLError} from 'components/customer/Preview3DCommon/store/viewerSlice';

// Redux mapDispatchToProps
const mapDispatch = {
    setHasWebGLError,
};

const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Preview3DErrorBoundaryProps extends PropsFromRedux {
    children: ReactNode;
}

interface Preview3DErrorBoundaryState {
    hasError: boolean;
    isWebGLError: boolean;
}

/**
 * Error boundary implementation for Preview3D components
 * @param {Preview3DErrorBoundaryProps} props:Preview3DErrorBoundaryProps
 * @return {React.ReactNode}
 */
class Preview3DErrorBoundary extends React.Component<
    Preview3DErrorBoundaryProps,
    Preview3DErrorBoundaryState
> {
    /**
     * Constructor
     * @param {Preview3DErrorBoundaryProps} props:Preview3DErrorBoundaryProps
     * @return {void}
     */
    constructor(props: Preview3DErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            isWebGLError: false,
        };
    }

    /**
     * Handles setting of state when error occured
     * @param {Error} error:Error
     * @return {void}
     */
    static getDerivedStateFromError(error: Error): Preview3DErrorBoundaryState {
        // Check if the error is related to WebGL
        const isWebGLError =
            error.message.includes('WebGL') ||
            error.message.includes('context');

        // Return the new state with the error and WebGL error status
        return {
            hasError: true,
            isWebGLError,
        };
    }

    /**
     * Executes upon loading
     * @return {void}
     */
    componentDidMount() {
        const {setHasWebGLError} = this.props;
        setHasWebGLError(false);
    }

    /**
     * Handle catching of exceptions
     * @return {void}
     */
    componentDidCatch() {
        const {isWebGLError} = this.state;

        // Dispatch the Redux action if it's a WebGL error
        if (isWebGLError) {
            const {setHasWebGLError} = this.props;
            setHasWebGLError(true);
        }
    }

    /**
     * Render the React element
     * @return {React.ReactNode}
     */
    render() {
        const {children} = this.props;
        const {hasError, isWebGLError} = this.state;

        if (hasError) {
            return isWebGLError ? null : <h1>Something went wrong.</h1>;
        }

        return children;
    }
}

export default connector(Preview3DErrorBoundary);
