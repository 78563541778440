import {useMemo, DependencyList} from 'react';
import useShelvesDynamicValues from 'components/customer/Preview3D/values/useShelvesDynamicValues';
import useDrawerDynamicValues from 'components/customer/Preview3D/values/useDrawerDynamicValues';
import useDoorDynamicValues from 'components/customer/Preview3D/values/useDoorDynamicValues';
import useDefaultDynamicValues from 'components/customer/Preview3D/values/useDefaultDynamicValues';
import useMultiPartDynamicValues from 'components/customer/Preview3D/values/useMultiPartDynamicValues';
import useRangehoodDynamicValues from 'components/customer/Preview3D/values/useRangehoodDynamicValues';
import useWallOvenDynamicValues from 'components/customer/Preview3D/values/useWallOvenDynamicValues';
import {ShelfType} from 'Preview3D/types';

const useDynamicValues = (shelfType: ShelfType, deps: DependencyList) => {
    const {shelfValues} = useShelvesDynamicValues(shelfType);
    const {drawerValues} = useDrawerDynamicValues();
    const {doorValues} = useDoorDynamicValues();
    const {generateDefaultValues} = useDefaultDynamicValues();
    const {multiPartValues} = useMultiPartDynamicValues();
    const {rangehoodValues} = useRangehoodDynamicValues();
    const {wallOvenValues} = useWallOvenDynamicValues();

    const dynamicValues = useMemo(() => {
        const values: Record<string, object> = {
            default: generateDefaultValues(),
        };

        const conditionalValues = [
            ...doorValues,
            ...shelfValues,
            ...drawerValues,
            ...multiPartValues,
            ...rangehoodValues,
            ...wallOvenValues,
        ];

        for (let index = 0; index < conditionalValues.length; index++) {
            const {key, condition, callback} = conditionalValues[Number(index)];

            if (condition)
                values[String(key)] = callback() as unknown as Record<
                    string,
                    object
                >;
        }

        return values;
    }, deps);

    return {
        dynamicValues,
    };
};

export default useDynamicValues;
