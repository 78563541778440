import React, {lazy} from 'react';
import {useProductItem} from 'hooks';
import {Formik} from 'formik';
import {useSearchParams} from 'react-router-dom';

const Preview3DComponent = lazy(
    () => import('components/customer/Preview3D/components/Preview3DComponent')
);

export const StandalonePreview3D = () => {
    const {initialValues, formSchema} = useProductItem();
    const [query] = useSearchParams();
    const view = query.get('view');

    return initialValues &&
        formSchema &&
        Object.keys(formSchema).length &&
        Object.keys(initialValues).length ? (
        // eslint-disable-next-line react/jsx-indent
        <Formik
            enableReinitialize={true}
            validationSchema={formSchema}
            initialValues={initialValues}
            onSubmit={undefined}>
            <form noValidate onSubmit={undefined}>
                <Preview3DComponent
                    previewLocation={'SPECS'}
                    fullScreen
                    raw={view?.toLowerCase() == 'raw'}
                />
            </form>
        </Formik>
    ) : null;
};

export default StandalonePreview3D;
