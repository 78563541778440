import {useRef, useState} from 'react';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {OperationGroup, Dimension} from 'Preview3D/types';
import {useProductContext} from 'contexts';
import {
    TemplateVariable,
    ProductDataStore,
} from 'components/customer/Preview3D/usePreview3DData';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

const useDoorAndDrawerAnimation = (isDoorOpen: boolean) => {
    const clickHandlers = useRef<((event?: MouseEvent) => void)[]>([]);
    const openDoorButtonRef = useRef<HTMLImageElement>(null);
    const isDoorOpenRef = useRef(isDoorOpen);
    const [buttonLabel, setButtonLabel] = useState('Open');
    const {exteriorThickness, carcaseThickness} = useMaterialTexture();

    const {values} = usePreviewFormValues();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {productDataStore} = useProductContext() as {
        productDataStore: ProductDataStore;
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );
    const isInnerDrawer = Boolean(variables.innerDrawerCount);
    const hasInsetDrawer = Boolean(variables.hasInsetDrawer);
    const isMicrowaveDrawer =
        typeof values.microwave_opening_height !== 'undefined' &&
        !variables?.isWallOvenProduct;

    const toggleDoorHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        isRightDoor = false,
        isPairDoor = false,
        isTopHangDoor = false,
        degrees = 120
    ) => {
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const size = new THREE.Vector3();
        boundingBox.getSize(size);

        const width = size.x;

        if (isRightDoor) {
            if (isPairDoor) {
                mesh.position.x = width;
                mesh.scale.x = -1;
            }
        } else {
            mesh.position.x = -width;
            mesh.scale.x = -1;

            if (isTopHangDoor) {
                mesh.position.y = size.y;
                mesh.scale.y = -1;
            }
        }

        const radians = (degrees * Math.PI) / 180;

        const initialRotation = isTopHangDoor
            ? {x: (90 * Math.PI) / 180}
            : {y: 0};
        const openRotation = isTopHangDoor
            ? {x: -(17 * Math.PI) / 180}
            : {y: isRightDoor ? radians : -radians};
        const closeRotation = isTopHangDoor
            ? {x: (90 * Math.PI) / 180}
            : {y: 0};

        const openDrawer = (animationSpeed = 1000) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .delay(
                    variables?.is3DoorProduct && animationSpeed !== 0 ? 300 : 0
                )
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .onUpdate(() => {
                    if (isTopHangDoor) mesh.rotation.x = initialRotation.x;
                    else mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        };

        const closeTween = new TWEEN.Tween(initialRotation)
            .to(closeRotation, 1000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .delay(variables?.is3DoorProduct ? 300 : 0)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                if (isTopHangDoor) mesh.rotation.x = initialRotation.x;
                else mesh.rotation.y = initialRotation.y;
            });

        const buttonRef = openDoorButtonRef.current;

        const handleClick = (event?: MouseEvent, shouldOpen?: boolean) => {
            event?.preventDefault();
            const isOpen = isDoorOpenRef.current;

            if (isOpen && !shouldOpen) {
                closeTween.start();
            } else {
                if (shouldOpen) openDrawer(0);
                else openDrawer();
            }
        };

        clickHandlers.current.push(handleClick);
        buttonRef?.addEventListener('click', handleClick, false);

        if (isDoorOpenRef.current) handleClick(undefined, true);
    };

    const toggleDrawerHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        isDrawerBottom = false,
        isDrawerBack = false,
        offset = -100,
        insert: Dimension,
        isInsetDrawer = false,
        noDepthAddition = false
    ) => {
        const depth = insert.y;
        const depthAddition =
            (isInnerDrawer || isInsetDrawer) && !noDepthAddition
                ? exteriorThickness
                : 0;
        const depthOffsetCalculation = variables?.isBinProduct ? 400 : offset;

        const initialPosition = {
            y: isDrawerBottom || isDrawerBack ? depth : depthAddition,
        };
        const openPosition = {
            y:
                isDrawerBottom || isDrawerBack
                    ? depth -
                      depthOffsetCalculation -
                      (isInsetDrawer ? exteriorThickness : 0)
                    : -depthOffsetCalculation,
        };
        const closePosition = {
            y:
                (isDrawerBottom || isDrawerBack
                    ? depth + depthAddition
                    : depthAddition) - 2,
        };

        const openDrawer = (animationSpeed = 600) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .delay(isInnerDrawer && !isDoorOpenRef.current ? 400 : 0)
                .onUpdate(() => {
                    mesh.position.y = initialPosition.y;
                });

            openTween.start();
        };

        const closeTween = new TWEEN.Tween(initialPosition)
            .to(closePosition, 600)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                mesh.position.y = initialPosition.y;
            });

        const buttonRef = openDoorButtonRef.current;

        const handleClick = (event?: MouseEvent, shouldOpen?: boolean) => {
            event?.preventDefault();
            const isOpen = isDoorOpenRef.current;

            if (isOpen && !shouldOpen) {
                closeTween.start();
            } else {
                if (shouldOpen) {
                    openDrawer(0);
                } else openDrawer();
            }
        };

        clickHandlers.current.push(handleClick);
        buttonRef?.addEventListener('click', handleClick, false);

        if (isDoorOpenRef.current) handleClick(undefined, true);
    };

    const toggleInnerDrawerHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        innerDrawerYPos: number,
        depthOffsetCalculation: 50
    ) => {
        const initialPosition = {
            y: innerDrawerYPos,
        };
        const openPosition = {
            y: innerDrawerYPos - depthOffsetCalculation,
        };
        const closePosition = {
            y: innerDrawerYPos,
        };

        const openDrawer = (animationSpeed = 600) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .delay(isInnerDrawer && !isDoorOpenRef.current ? 400 : 0)
                .onUpdate(() => {
                    mesh.position.y = initialPosition.y;
                });

            openTween.start();
        };

        const closeTween = new TWEEN.Tween(initialPosition)
            .to(closePosition, 600)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                mesh.position.y = initialPosition.y;
            });

        const buttonRef = openDoorButtonRef.current;

        const handleClick = (event?: MouseEvent, shouldOpen?: boolean) => {
            event?.preventDefault();
            const isOpen = isDoorOpenRef.current;

            if (isOpen && !shouldOpen) {
                closeTween.start();
            } else {
                if (shouldOpen) {
                    openDrawer(0);
                } else openDrawer();
            }
        };

        clickHandlers.current.push(handleClick);
        buttonRef?.addEventListener('click', handleClick, false);

        if (isDoorOpenRef.current) handleClick(undefined, true);
    };

    const toggleMainBifoldDoorHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        isRightLShape = false
    ) => {
        let initialRotation = {y: (90 * Math.PI) / 180};
        let openRotation = {y: 0};
        let closeRotation = {y: (90 * Math.PI) / 180};

        if (isRightLShape) {
            const boundingBox = new THREE.Box3().setFromObject(mesh);
            const size = new THREE.Vector3();
            boundingBox.getSize(size);

            const width = size.x;

            mesh.scale.x = -1;
            mesh.position.x = width;

            initialRotation = {y: 0};
            openRotation = {y: (90 * Math.PI) / 180};
            closeRotation = {y: 0};
        }

        const openDrawer = (animationSpeed = 1000) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        };

        const closeTween = new TWEEN.Tween(initialRotation)
            .to(closeRotation, 1000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                mesh.rotation.y = initialRotation.y;
            });

        const buttonRef = openDoorButtonRef.current;

        const handleClick = (event?: MouseEvent, shouldOpen?: boolean) => {
            event?.preventDefault();
            const isOpen = isDoorOpenRef.current;

            if (isOpen && !shouldOpen) {
                closeTween.start();
            } else {
                if (shouldOpen) openDrawer(0);
                else openDrawer();
            }
        };

        clickHandlers.current.push(handleClick);
        buttonRef?.addEventListener('click', handleClick, false);

        if (isDoorOpenRef.current) handleClick(undefined, true);
    };

    const toggleExtendedBifoldDoorHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        meshPos: Dimension,
        isRightLShape = false,
        isLeftBifoldExtension = false
    ) => {
        let initialPosition = {y: meshPos.y, x: meshPos.x};
        let openPosition = {
            y:
                -exteriorThickness -
                carcaseThickness +
                Number(values.cabinet_door_left),
            x:
                meshPos.x +
                (values.cabinet_left_width - values.cabinet_right_depth) -
                (exteriorThickness - carcaseThickness) -
                Number(values.cabinet_door_left),
        };
        let closePosition = {y: meshPos.y, x: meshPos.x};

        if (isRightLShape) {
            const boundingBox = new THREE.Box3().setFromObject(mesh);
            const size = new THREE.Vector3();
            boundingBox.getSize(size);

            const depth = size.x - exteriorThickness;

            initialPosition = {
                y:
                    depth +
                    Number(values.cabinet_door_left) +
                    (exteriorThickness - carcaseThickness) -
                    1,
                x: meshPos.x + exteriorThickness,
            };
            openPosition = {
                y:
                    values.cabinet_left_width -
                    values.cabinet_right_depth -
                    (values.cabinet_right_width - values.cabinet_left_depth) +
                    (exteriorThickness - carcaseThickness) +
                    Number(values.cabinet_door_right),
                x:
                    meshPos.x +
                    (values.cabinet_right_width - values.cabinet_left_depth) +
                    exteriorThickness +
                    carcaseThickness -
                    Number(values.cabinet_door_right),
            };
            closePosition = {
                y:
                    depth +
                    Number(values.cabinet_door_left) +
                    (exteriorThickness - carcaseThickness) -
                    1,
                x: meshPos.x + exteriorThickness,
            };

            if (variables?.is3DoorProduct && !isLeftBifoldExtension) {
                const thicknessDiff = exteriorThickness - carcaseThickness;

                initialPosition.y = closePosition.y =
                    values.cabinet_left_width -
                    values.cabinet_right_depth -
                    carcaseThickness -
                    thicknessDiff;
            }
        }

        const openDoor = (animationSpeed = 1000) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                })
                .delay(animationSpeed ? 100 : 0)
                .onUpdate(() => {
                    if (isRightLShape) mesh.position.x = initialPosition.x;
                    else mesh.position.y = initialPosition.y;
                });

            openTween.start();
        };

        const openDoorX = (animationSpeed = 700) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                })
                .onUpdate(() => {
                    if (isRightLShape) mesh.position.y = initialPosition.y;
                    else mesh.position.x = initialPosition.x;
                });

            openTween.start();
        };

        const closeTween = new TWEEN.Tween(initialPosition)
            .to(closePosition, 750)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                if (isRightLShape) mesh.position.x = initialPosition.x;
                else mesh.position.y = initialPosition.y;
            });

        const closeTweenX = new TWEEN.Tween(initialPosition)
            .to(closePosition, 1200)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .delay(75)
            .onUpdate(() => {
                if (isRightLShape) mesh.position.y = initialPosition.y;
                else mesh.position.x = initialPosition.x;
            });

        let initialRotation = {y: (60 * Math.PI) / 180};
        let openRotation = {y: -((90 * Math.PI) / 180)};
        let closeRotation = {y: 0};

        if (isRightLShape) {
            initialRotation = {y: (60 * Math.PI) / 180};
            openRotation = {y: 0};
            closeRotation = {y: -((90 * Math.PI) / 180)};
        }

        if (isLeftBifoldExtension) {
            initialRotation = {y: (90 * Math.PI) / 180};
            openRotation = {y: -((60 * Math.PI) / 180)};
            closeRotation = {y: (90 * Math.PI) / 180};
        }

        const openDoor2 = (animationSpeed = 1000) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .delay(animationSpeed)
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        };

        const closeTween2 = new TWEEN.Tween(initialRotation)
            .to(closeRotation, 1000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .delay(isLeftBifoldExtension ? 300 : 0)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                mesh.rotation.y = initialRotation.y;
            });

        const buttonRef = openDoorButtonRef.current;

        const handleClick = (event?: MouseEvent, shouldOpen?: boolean) => {
            event?.preventDefault();
            const isOpen = isDoorOpenRef.current;

            if (isLeftBifoldExtension) {
                if (isOpen && !shouldOpen) {
                    closeTween2.start();
                } else {
                    if (shouldOpen) {
                        openDoor2(0);
                    } else {
                        openDoor2();
                    }
                }
            } else {
                if (isOpen && !shouldOpen) {
                    closeTween.start();
                    closeTween2.start();
                    closeTweenX.start();
                } else {
                    if (shouldOpen) {
                        openDoor(0);
                        openDoor2(0);
                        openDoorX(0);
                    } else {
                        openDoor();
                        openDoor2();
                        openDoorX();
                    }
                }
            }
        };

        clickHandlers.current.push(handleClick);
        buttonRef?.addEventListener('click', handleClick, false);

        if (isDoorOpenRef.current) handleClick(undefined, true);
    };

    const toggleCornerHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        isRightDoor = false
    ) => {
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const size = new THREE.Vector3();
        boundingBox.getSize(size);

        if (isRightDoor) {
            mesh.position.z =
                -(values.cabinet_left_width - values.cabinet_right_depth) -
                exteriorThickness;
            mesh.position.x =
                values.cabinet_right_width - values.cabinet_left_depth;
            mesh.scale.x = -1;
        }

        const degrees = 90;
        const radians = (degrees * Math.PI) / 180;

        const initialRotation = {y: (45 * Math.PI) / 180};
        const openRotation = {
            y: isRightDoor ? (170 * Math.PI) / 180 : -radians,
        };
        const closeRotation = {y: (45 * Math.PI) / 180};

        const openDrawer = (animationSpeed = 1000) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        };

        const closeTween = new TWEEN.Tween(initialRotation)
            .to(closeRotation, 1000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                mesh.rotation.y = initialRotation.y;
            });

        const buttonRef = openDoorButtonRef.current;

        const handleClick = (event?: MouseEvent, shouldOpen?: boolean) => {
            event?.preventDefault();
            const isOpen = isDoorOpenRef.current;

            if (isOpen && !shouldOpen) {
                closeTween.start();
            } else {
                if (shouldOpen) openDrawer(0);
                else openDrawer();
            }
        };

        clickHandlers.current.push(handleClick);
        buttonRef?.addEventListener('click', handleClick, false);

        if (isDoorOpenRef.current) handleClick(undefined, true);
    };

    const calculateBaseWithAngle = (
        hypotenuse: number,
        angleInDegrees: number
    ): number => {
        const angleInRadians = angleInDegrees * (Math.PI / 180);
        const base = hypotenuse * Math.cos(angleInRadians);
        return base;
    };

    const toggleApplianceExtendedDoorHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        isRightExtendedDoor = false
    ) => {
        const openingAngle = 87;
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const size = new THREE.Vector3();
        boundingBox.getSize(size);

        const width = size.x;

        if (isRightExtendedDoor) {
            mesh.position.x = -width;
            mesh.scale.x = -1;
        } else {
            mesh.position.x = width;
            mesh.scale.x = -1;
        }

        const base = calculateBaseWithAngle(width, openingAngle);

        const radians = (openingAngle * Math.PI) / 180;
        const initialRotation = {y: 0};
        const openRotation = {y: isRightExtendedDoor ? -radians : radians};
        const closeRotation = {y: 0};

        const openDoor = (animationSpeed = 600) => {
            const openTween = new TWEEN.Tween(initialRotation)
                .to(openRotation, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .onUpdate(() => {
                    mesh.rotation.y = initialRotation.y;
                });

            openTween.start();
        };

        const closeTween = new TWEEN.Tween(initialRotation)
            .to(closeRotation, 1000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                mesh.rotation.y = initialRotation.y;
            });

        const cabinetWidth = Number(values.cabinet_width) + carcaseThickness;
        const doorGap = Number(values.cabinet_door_gap);
        const threeDoorWidth = (cabinetWidth - doorGap * 2) / 3;

        const position = {
            x: variables?.isApplianceLeftBifoldRight
                ? threeDoorWidth * 2 + doorGap + 2
                : isRightExtendedDoor
                ? variables?.isApplianceLeftRightBifold
                    ? threeDoorWidth + doorGap
                    : -1
                : cabinetWidth - Number(values.cabinet_door_right) + 1,
        };

        let initialPosition = {...position};
        const openPosition = {
            x: isRightExtendedDoor
                ? cabinetWidth - base * 2 - Number(values.cabinet_door_right)
                : base * 2 + Number(values.cabinet_door_left),
        };
        let closePosition = {...position};

        if (variables?.isApplianceBifoldPair) {
            const fourDoorWidth = (cabinetWidth - doorGap * 3) / 4;

            if (isRightExtendedDoor) {
                const positionRight = {x: fourDoorWidth * 2 + doorGap * 2 - 2};
                initialPosition = {...positionRight};
                closePosition = {...positionRight};
            } else {
                const positionRight = {x: fourDoorWidth * 2 + doorGap};
                initialPosition = {...positionRight};
                closePosition = {...positionRight};
            }
        }

        const openDoorX = (animationSpeed = 1000) => {
            const openTween = new TWEEN.Tween(initialPosition)
                .to(openPosition, animationSpeed)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    isDoorOpenRef.current = true;
                    setButtonLabel('Close');
                })
                .delay(0)
                .onUpdate(() => {
                    mesh.position.x = initialPosition.x;
                });

            openTween.start();
        };

        const closeTween2 = new TWEEN.Tween(initialPosition)
            .to(closePosition, 600)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                isDoorOpenRef.current = false;
                setButtonLabel('Open');
            })
            .onUpdate(() => {
                mesh.position.x = initialPosition.x;
            });

        const buttonRef = openDoorButtonRef.current;

        const handleClick = (event?: MouseEvent, shouldOpen?: boolean) => {
            event?.preventDefault();
            const isOpen = isDoorOpenRef.current;

            if (isOpen && !shouldOpen) {
                closeTween.start();
                closeTween2.start();
            } else {
                if (shouldOpen) {
                    openDoor(0);
                    openDoorX(0);
                } else {
                    openDoor();
                    openDoorX();
                }
            }
        };

        clickHandlers.current.push(handleClick);
        buttonRef?.addEventListener('click', handleClick, false);

        if (isDoorOpenRef.current) handleClick(undefined, true);
    };

    const parseButtonLabel = () => {
        let product = 'Doors';

        if (values.drawers) {
            product = 'Drawers';
            if (values.shelves) {
                product = 'Doors and Drawers';
            }
        }
        return `${buttonLabel} ${product}`;
    };

    const animationHandler = (
        mesh: THREE.Group<THREE.Object3DEventMap>,
        operationGroup: OperationGroup,
        meshPos: Dimension,
        counter: number
    ) => {
        if (operationGroup.IsRightDoor) {
            toggleDoorHandler(mesh, true, operationGroup.IsPairDoor);
        }

        if (operationGroup.IsLeftDoor) {
            toggleDoorHandler(mesh, false, operationGroup.IsPairDoor);
        }

        if (operationGroup.IsTopHangDoor) {
            toggleDoorHandler(mesh, false, false, true);
        }

        if (
            operationGroup.IsCornerLeftDoor ||
            operationGroup.IsCornerRightDoor
        ) {
            toggleCornerHandler(
                mesh,
                Boolean(operationGroup.IsCornerRightDoor)
            );
        }

        if (operationGroup.IsDrawerFront || operationGroup.IsDoorPullout) {
            const depthOffsetCalculation = 50 * (counter + 1);
            toggleDrawerHandler(
                mesh,
                false,
                false,
                operationGroup.IsDoorPullout
                    ? 250
                    : depthOffsetCalculation >= 200
                    ? 200
                    : depthOffsetCalculation,
                meshPos,
                hasInsetDrawer || isMicrowaveDrawer
            );
        }

        if (operationGroup.IsDrawerBottom) {
            const depthOffsetCalculation = 50 * (counter + 1);
            toggleDrawerHandler(
                mesh,
                true,
                false,
                depthOffsetCalculation >= 200 ? 200 : depthOffsetCalculation,
                meshPos,
                hasInsetDrawer || isMicrowaveDrawer,
                isMicrowaveDrawer
            );
        }

        if (operationGroup.IsDrawerBack) {
            const depthOffsetCalculation = 50 * (counter + 1);
            toggleDrawerHandler(
                mesh,
                false,
                true,
                depthOffsetCalculation >= 200 ? 200 : depthOffsetCalculation,
                meshPos,
                hasInsetDrawer || isMicrowaveDrawer,
                isMicrowaveDrawer
            );
        }

        if (operationGroup.IsRightLShape) {
            if (operationGroup.IsBifoldRightDoor) {
                toggleMainBifoldDoorHandler(mesh, true);
            }

            if (operationGroup.IsBifoldLeftDoor) {
                toggleExtendedBifoldDoorHandler(
                    mesh,
                    meshPos,
                    true,
                    Boolean(operationGroup.IsLeftBifoldExtension)
                );
            }
        } else {
            if (operationGroup.IsBifoldLeftDoor) {
                toggleMainBifoldDoorHandler(mesh);
            }

            if (operationGroup.IsBifoldRightDoor) {
                toggleExtendedBifoldDoorHandler(mesh, meshPos);
            }
        }

        if (operationGroup.IsLeftApplianceMainDoor) {
            toggleDoorHandler(mesh, false, false, false, 87);
        }

        if (operationGroup.IsLeftApplianceExtendedDoor) {
            toggleApplianceExtendedDoorHandler(mesh);
        }

        if (operationGroup.IsRightApplianceMainDoor) {
            toggleDoorHandler(mesh, true, true, false, 87);
        }

        if (operationGroup.IsRightApplianceExtendedDoor) {
            toggleApplianceExtendedDoorHandler(mesh, true);
        }
    };

    const clearHandlers = () => {
        clickHandlers.current?.forEach((handler) => {
            openDoorButtonRef.current?.removeEventListener('click', handler);
        });
    };

    return {
        setButtonLabel,
        buttonLabel,
        parseButtonLabel,
        animationHandler,
        openDoorButtonRef,
        isDoorOpenRef,
        clearHandlers,
        toggleInnerDrawerHandler,
    };
};

export default useDoorAndDrawerAnimation;
