import React from 'react';
import {ProductProvider} from 'contexts';
import StandalonePreview3D from 'components/customer/Preview3D/StandalonePreview3D';

const StandalonePreview3DWrapper = () => {
    return (
        <ProductProvider>
            <StandalonePreview3D />
        </ProductProvider>
    );
};

export default StandalonePreview3DWrapper;
