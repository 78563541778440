export const makeZeroIfUndefined = (value: string | number): number => {
    if (typeof value === 'undefined') return 0;

    return Number(value);
};

export const convertShelfPositionToPercentage = (
    value: number,
    height: number
): number => {
    if (value > 1) return value / height;

    return value;
};

export const getShelfStyle = (shelfStyle: number): string =>
    Number(shelfStyle) === 0 ? 'Fixed' : 'Adj';
