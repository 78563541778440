import React from 'react';
import * as THREE from 'three';

const useLightingSetup = (scene: React.MutableRefObject<THREE.Scene>) => {
    const setupLighting = () => {
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
        directionalLight2.position.set(1, -1, 0);
        scene.current.add(directionalLight2);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
        directionalLight.position.set(1, 1, 1);
        scene.current.add(directionalLight);

        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.current.add(ambientLight);
    };

    return {
        setupLighting,
    };
};

export default useLightingSetup;
