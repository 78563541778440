import {useCallback} from 'react';
import * as THREE from 'three';
import {
    Batch,
    OperationGroup,
    Dimension,
    Edge,
    PreviewLocation,
} from 'Preview3D/types';
import {
    readBatch,
    createPanelGeometry,
    Node,
} from 'components/customer/Preview3D/helpers';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {MaterialType} from 'components/customer/Materials/entity';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {useProductContext} from 'contexts';
import {
    TemplateVariable,
    ProductDataStore,
} from 'components/customer/Preview3D/usePreview3DData';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

const usePreview3D = () => {
    const selectedExteriorMaterial = useAppSelector(
        (state) => getMaterial(state, MaterialType.EXTERIOR),
        shallowEqual
    );
    const selectedCarcaseMaterial = useAppSelector(
        (state) => getMaterial(state, MaterialType.CARCASE),
        shallowEqual
    );
    const selectedExteriorMaterialEdge = useAppSelector((state) =>
        getEdge(state, MaterialType.EXTERIOR)
    );
    const selectedCarcaseMaterialEdge = useAppSelector((state) =>
        getEdge(state, MaterialType.CARCASE)
    );

    const {values} = usePreviewFormValues();
    const {
        exteriorThickness,
        carcaseThickness,
        carcaseSubstrateMaterial,
        exteriorSubstrateMaterial,
    } = useMaterialTexture();

    const horizontalGrainExt = values.hor_grain_ext;
    const horizontalGrainCarc = values.hor_grain_carc;
    const includeHardware = values.cabinet_include_hardware;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {productDataStore} = useProductContext() as {
        productDataStore: ProductDataStore;
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const edging: Edge | null =
        typeof values.cabinet_edge_L1 !== 'undefined' ||
        typeof values.panel_edge_top !== 'undefined'
            ? {
                  l1:
                      Boolean(parseInt(values.cabinet_edge_W1 as string)) ||
                      Boolean(parseInt(values.panel_edge_left as string)),
                  l2:
                      Boolean(parseInt(values.cabinet_edge_W2 as string)) ||
                      Boolean(parseInt(values.panel_edge_right as string)),
                  w1:
                      Boolean(parseInt(values.cabinet_edge_L1 as string)) ||
                      Boolean(parseInt(values.panel_edge_top as string)),
                  w2:
                      Boolean(parseInt(values.cabinet_edge_L2 as string)) ||
                      Boolean(parseInt(values.panel_edge_bottom as string)),
              }
            : null;

    const loadDataXML = (rawTemplate: string): object => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(rawTemplate, 'text/xml');
        return readBatch({}, xmlDoc.children[0] as unknown as Node);
    };

    const createSceneXML = useCallback(
        (
            batch: Batch,
            scene: THREE.Scene,
            animationHandler: (
                mesh: THREE.Group<THREE.Object3DEventMap>,
                operationGroup: OperationGroup,
                meshPos: Dimension,
                counter: number
            ) => void,
            showTexture?: boolean,
            includeDrawerFaces?: boolean,
            partiallyTransparentSegment?: boolean,
            showShelfLabel?: boolean,
            previewLocation?: PreviewLocation,
            shapedPanelHandler?: () => THREE.Mesh[]
        ) => {
            if (typeof batch === 'undefined' || batch == null) return;

            const cabinetGroup = new THREE.Group();
            let counter = 0;

            for (const job of Object.values(batch.Jobs)) {
                for (const manufacturing of Object.values(job.Manufacturing)) {
                    const operationGroups = Object.values(
                        manufacturing.OperationGroups
                    );

                    for (const operationGroup of operationGroups) {
                        const part = Object.values(job.Items)[0].Parts[
                            operationGroup.PartID
                        ];

                        const partName = part.Description?.split(' ');
                        const searchWords = 'Base Right Side'.split(' ');

                        const isRightSideSubstringPresent = searchWords.every(
                            (word) => partName.includes(word)
                        );

                        const transparentRightSidePanel =
                            isRightSideSubstringPresent &&
                            previewLocation === 'SHELVES';

                        if (
                            !includeDrawerFaces &&
                            operationGroup.ExteriorMaterial
                        ) {
                            continue;
                        }

                        const mesh = createPanelGeometry(
                            part,
                            operationGroup,
                            '#000',
                            showTexture,
                            selectedExteriorMaterial?.image,
                            selectedCarcaseMaterial?.image,
                            selectedExteriorMaterialEdge?.image,
                            selectedCarcaseMaterialEdge?.image,
                            true,
                            Boolean(operationGroup.ExteriorMaterial) ||
                                Boolean(operationGroup.CarcaseMaterial)
                                ? operationGroup.ExteriorMaterial?.toString() ===
                                      'true'
                                : null,
                            Boolean(horizontalGrainExt),
                            Boolean(horizontalGrainCarc),
                            edging,
                            (!operationGroup.ExteriorMaterial &&
                                selectedCarcaseMaterial?.brand_id === 23) ||
                                (operationGroup.IsDrawerRunner &&
                                    !Boolean(values.cabinet_include_hardware)),
                            operationGroup.IsDrawerRunner ||
                                (operationGroup.IsDrawerFront &&
                                    Boolean(variables?.innerDrawerCount)),
                            {
                                exteriorThickness,
                                carcaseThickness,
                            },
                            Boolean(operationGroup.HideSgement) ||
                                transparentRightSidePanel,
                            partiallyTransparentSegment ||
                                transparentRightSidePanel,
                            operationGroup?.ShelfLabel,
                            showShelfLabel,
                            carcaseSubstrateMaterial,
                            exteriorSubstrateMaterial,
                            includeHardware,
                            variables?.isLeftReturn,
                            variables?.isReturnProduct &&
                                !variables?.isLeftReturn,
                            operationGroups?.length,
                            variables?.isUpperProduct ||
                                variables?.isApplianceProduct,
                            Boolean(operationGroup.NoEdging),
                            operationGroup.IsRightDoor ||
                                operationGroup.IsPairDoor ||
                                operationGroup.IsLeftDoor ||
                                operationGroup.IsRightDoor ||
                                operationGroup.IsDoorPullout ||
                                operationGroup.IsTopHangDoor ||
                                operationGroup.IsBifoldLeftDoor ||
                                operationGroup.IsBifoldLeftDoor ||
                                operationGroup.IsBifoldRightDoor ||
                                operationGroup.IsCornerLeftDoor ||
                                operationGroup.IsCornerRightDoor ||
                                operationGroup.IsDrawerFront ||
                                operationGroup.WithEdging ||
                                operationGroup.IsLeftApplianceMainDoor ||
                                operationGroup.IsLeftApplianceExtendedDoor ||
                                operationGroup.IsRightApplianceMainDoor ||
                                operationGroup.IsRightApplianceExtendedDoor ||
                                (operationGroup.ExteriorMaterial &&
                                    variables?.isReturnProduct),
                            Boolean(operationGroup.FlipSubstrate),
                            Boolean(operationGroup.IsFrontRangehoodVent),
                            Boolean(operationGroup.WithTopEdging),
                            Boolean(operationGroup.WithFrontEdging),
                            Boolean(operationGroup.DynamicExteriorEdging)
                        );

                        const meshPos = operationGroup.Operations[0].Insert;
                        const meshRot = operationGroup.Operations[0].Rotation;
                        const meshNormal =
                            operationGroup.Operations[0].Segments[0]
                                .StartNormal;

                        const matrixRot = new THREE.Matrix4();

                        matrixRot.makeRotationAxis(
                            new THREE.Vector3(
                                -meshNormal.x,
                                -meshNormal.y,
                                -meshNormal.z
                            ),
                            THREE.MathUtils.degToRad(meshRot + 90)
                        );

                        const matrix = new THREE.Matrix4();

                        const up =
                            meshNormal.z == 0
                                ? new THREE.Vector3(0, 0, 1)
                                : new THREE.Vector3(0, 1, 0);

                        if (
                            operationGroup.MfgOrientationID !== undefined &&
                            meshNormal.z != 0
                        ) {
                            up.negate();
                        }

                        if (
                            operationGroup.IsDrawerFront ||
                            operationGroup.IsDoorPullout
                        )
                            counter++;

                        animationHandler(
                            mesh,
                            operationGroup,
                            meshPos,
                            counter
                        );

                        matrix.lookAt(
                            new THREE.Vector3(0, 0, 0),
                            new THREE.Vector3(
                                meshNormal.x,
                                meshNormal.y,
                                meshNormal.z
                            ),
                            up
                        );

                        matrixRot.multiply(matrix);

                        matrixRot.setPosition(
                            new THREE.Vector3(meshPos.x, meshPos.y, meshPos.z)
                        );

                        mesh.applyMatrix4(matrixRot);

                        if (operationGroup.IsBifoldLeftDoor) {
                            mesh.rotation.y = (90 * Math.PI) / 180;
                        }

                        if (
                            operationGroup.IsCornerLeftDoor ||
                            operationGroup.IsCornerRightDoor
                        ) {
                            mesh.rotation.y = (45 * Math.PI) / 180;
                        }

                        if (operationGroup.FlipY) {
                            mesh.rotation.y = (180 * Math.PI) / 180;
                        }

                        cabinetGroup.add(mesh);
                    }
                }
            }

            const box = new THREE.Box3().setFromObject(cabinetGroup);
            const cabinetSize = box.getSize(new THREE.Vector3());
            cabinetGroup.position.set(
                -box.min.x - cabinetSize.x / 2,
                -box.min.y - cabinetSize.y / 2,
                0
            );

            const meshes = shapedPanelHandler() ?? [];

            meshes?.forEach((mesh) => {
                cabinetGroup.add(mesh);
            });

            scene.add(cabinetGroup);
        },
        [
            selectedExteriorMaterial,
            selectedCarcaseMaterial,
            horizontalGrainExt,
            horizontalGrainCarc,
            selectedExteriorMaterialEdge,
            selectedCarcaseMaterialEdge,
            edging,
            includeHardware,
        ]
    );

    return {loadDataXML, createSceneXML};
};

export default usePreview3D;
