import * as THREE from 'three';
import {OperationGroup, Dimension, Panel, Edge} from 'Preview3D/types';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';

export const vecFromObj = (obj: Dimension) => {
    return new THREE.Vector3(obj.x, obj.y, obj.z);
};

export const planeTransformFromNormal = (
    planeNormal: Dimension,
    part: Panel
) => {
    const planeMatrix = new THREE.Matrix4();
    if (planeNormal.x > 0) {
        planeMatrix.makeBasis(
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(1, 0, 0)
        );
        planeMatrix.setPosition(new THREE.Vector3(part.Length, 0, 0));
    } else if (planeNormal.x < 0) {
        planeMatrix.makeBasis(
            new THREE.Vector3(0, -1, 0),
            new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(-1, 0, 0)
        );
    } else if (planeNormal.y > 0) {
        planeMatrix.makeBasis(
            new THREE.Vector3(-1, 0, 0),
            new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(0, 1, 0)
        );
        planeMatrix.setPosition(new THREE.Vector3(0, part.Width, 0));
    } else if (planeNormal.y < 0) {
        planeMatrix.makeBasis(
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(0, -1, 0)
        );
    } else if (planeNormal.z < 0) {
        planeMatrix.makeBasis(
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3(0, 0, -1)
        );
        planeMatrix.setPosition(new THREE.Vector3(0, 0, -part.Thickness));
    }
    return planeMatrix;
};

export const reduceMaterialOpacity = (
    material: THREE.Material,
    opacity = 0.1
) => {
    if (material) {
        material.transparent = true;
        material.opacity = opacity;
    }
};

export const addLabelMesh = (
    shelfLabel: string,
    size: THREE.Vector3,
    thickness: number,
    parentMesh:
        | THREE.Mesh
        | THREE.LineSegments<
              THREE.EdgesGeometry<THREE.ExtrudeGeometry>,
              THREE.LineBasicMaterial,
              THREE.Object3DEventMap
          >,
    isLeftReturnProduct: boolean,
    isRightReturnProduct: boolean
) => {
    const fontLoader = new FontLoader();

    fontLoader.load(
        '/templates/3D/fonts/Poppins SemiBold_Regular.json',
        (font) => {
            const textGeometry = new TextGeometry(shelfLabel, {
                font,
                size: 4,
                height: 1,
                depth: 0.5,
            });

            const textMesh = new THREE.Mesh(
                textGeometry,
                new THREE.MeshStandardMaterial({
                    color: '#333',
                })
            );

            const scale = 7;
            textMesh.scale.set(scale, scale, scale);

            const boundingBox = new THREE.Box3().setFromObject(textMesh);
            const meshWidth = boundingBox.max.x - boundingBox.min.x;

            textMesh.rotation.x = -(90 * Math.PI) / 180;

            let positionX = size.x - meshWidth / 2;

            if (isRightReturnProduct) positionX = 10;
            if (isLeftReturnProduct) positionX = size.x * 2 - meshWidth - 10;

            textMesh.position.set(positionX, size.y * 2 - 20, -thickness - 3.5);

            parentMesh.add(textMesh);
        }
    );
};

// Reads batch type
export const createPanelGeometry = (
    part: Panel,
    operationsGroup: OperationGroup,
    color = '#000',
    showTexture = false,
    materialImage: string | null = null,
    carcaseMaterialImage: string | null = null,
    materialEdgeImage: string | null = null,
    carcaseMaterialEdgeImage: string | null = null,
    shouldApplyMaterial = false,
    isExteriorMaterial = false,
    horizontalGrainExt = false,
    horizontalGrainCarc = false,
    edging: Edge | null = null,
    nonSupplyCarcase = false,
    plainWhiteColor = false,
    thickness: {exteriorThickness: number; carcaseThickness: number} = {
        exteriorThickness: 16.5,
        carcaseThickness: 16.5,
    },
    hideSgement = false,
    partiallyTransparentSegment = false,
    shelfLabel = '',
    showShelfLabel = false,
    carcaseSubstrateMaterial: THREE.MeshStandardMaterial = null,
    exteriorSubstrateMaterial: THREE.MeshStandardMaterial = null,
    includeHardware = false,
    isLeftReturnProduct = false,
    isRightReturnProduct = false,
    operationCount = 0,
    isEdgedBottom = false,
    noEdging = false,
    shouldApplyEdgingParam = false,
    flipSubstrate = false,
    isFrontRangehoodVent = false,
    withTopEdging = false,
    withFrontEdging = false,
    dynamicExteriorEdging = false
) => {
    const shouldApplyEdging = shouldApplyEdgingParam && !dynamicExteriorEdging;

    const segmentMaterial = new THREE.LineBasicMaterial({
        color,
    });

    const panelGroup = new THREE.Group();

    let hasPerimeterShape = false;
    for (const operation of operationsGroup.Operations) {
        if (operation.Type === 'perimeter_shape') {
            hasPerimeterShape = true;
        }
    }

    for (const operation of operationsGroup.Operations) {
        if (operation.Type === 'panel' && hasPerimeterShape) {
            continue;
        }

        if (operation.Segments !== undefined) {
            const shape = new THREE.Shape();
            for (const segment of operation.Segments) {
                const bulge = Math.abs(segment.StartCoor.w);
                const startPoint = vecFromObj(segment.StartCoor);
                const endPoint = vecFromObj(segment.EndCoor);

                if (bulge === 0) {
                    // geometry.vertices.push(start_point, end_point);
                    shape.moveTo(startPoint.x, startPoint.y);
                    shape.lineTo(endPoint.x, endPoint.y);
                } else {
                    // Line from start to end
                    const line = endPoint.clone().sub(startPoint);
                    // Midpoint between start and end
                    const midpoint = line
                        .clone()
                        .multiplyScalar(0.5)
                        .add(startPoint);
                    // Arc direction is defined by the sign of the w component
                    const clockwise = segment.StartCoor.w < 0;

                    // Normalised tangent from start to end
                    const tangent = line.clone().normalize();
                    // Counter clockwise normal
                    const normal = new THREE.Vector2(-tangent.y, tangent.x);
                    // Flip normal if clockwise
                    if (clockwise) {
                        normal.negate();
                    }

                    const sagitta = (line.length() / 2) * bulge;
                    const radius =
                        (sagitta * sagitta +
                            (line.length() / 2) * (line.length() / 2)) /
                        (2 * sagitta);

                    // Distance from the line midpoint to the arc center
                    const distanceToCenter = radius - sagitta;
                    const center = normal
                        .clone()
                        .multiplyScalar(distanceToCenter)
                        .add(midpoint);

                    const startPoint2d = new THREE.Vector2(
                        startPoint.x,
                        startPoint.y
                    );
                    const endPoint2d = new THREE.Vector2(
                        endPoint.x,
                        endPoint.y
                    );
                    const startAngle = startPoint2d.clone().sub(center).angle();
                    const endAngle = endPoint2d.clone().sub(center).angle();

                    shape.absarc(
                        center.x,
                        center.y,
                        radius,
                        startAngle,
                        endAngle,
                        clockwise
                    );
                }
            }

            const extrudeSettings = {
                curveSegments: 24,
                depth: isExteriorMaterial
                    ? -thickness.exteriorThickness
                    : -thickness.carcaseThickness,
                bevelEnabled: false,
            };

            const geometry = new THREE.EdgesGeometry(
                new THREE.ExtrudeGeometry(shape, extrudeSettings)
            );

            segmentMaterial.visible = false;

            if (showTexture) {
                const extrudeGeometryForBox = new THREE.ExtrudeGeometry(shape, {
                    ...extrudeSettings,
                });

                const box = new THREE.Box3().setFromObject(
                    new THREE.Mesh(extrudeGeometryForBox)
                );
                const size = new THREE.Vector3();
                box.getSize(size);

                const boxGeometry = new THREE.BoxGeometry(
                    size.x,
                    size.y,
                    size.z
                );
                box.getCenter(size);

                const textureLoader = new THREE.TextureLoader();

                textureLoader.load(
                    isExteriorMaterial ? materialImage : carcaseMaterialImage,
                    (texture) => {
                        void (async () => {
                            texture.generateMipmaps = true;
                            texture.minFilter = THREE.LinearMipmapLinearFilter;
                            texture.magFilter = THREE.LinearFilter;

                            if (
                                (!horizontalGrainExt && isExteriorMaterial) ||
                                (!horizontalGrainCarc &&
                                    !isExteriorMaterial &&
                                    !isFrontRangehoodVent) ||
                                (isFrontRangehoodVent && horizontalGrainCarc)
                            ) {
                                texture.rotation = (Math.PI / 4) * 2;
                                texture.center = new THREE.Vector2(0.5, 0.5);
                                texture.needsUpdate = true;
                            }

                            let material:
                                | THREE.MeshStandardMaterial
                                | THREE.MeshStandardMaterial[] =
                                new THREE.MeshStandardMaterial({
                                    ...(shouldApplyMaterial
                                        ? {map: texture}
                                        : {color: '#fff'}),
                                });

                            if (shouldApplyMaterial) {
                                const loader = new THREE.TextureLoader();
                                let edgeTexture = null;

                                if (isExteriorMaterial) {
                                    try {
                                        edgeTexture = await loader.loadAsync(
                                            materialEdgeImage
                                        );
                                    } catch {}
                                } else {
                                    try {
                                        edgeTexture = await loader.loadAsync(
                                            carcaseMaterialEdgeImage
                                        );
                                    } catch {}
                                }

                                const edgeMaterial =
                                    new THREE.MeshStandardMaterial(
                                        plainWhiteColor
                                            ? {
                                                  color: '#F7F8F5',
                                              }
                                            : {
                                                  map: edgeTexture || texture,
                                              }
                                    );
                                const faceMaterial =
                                    new THREE.MeshStandardMaterial(
                                        plainWhiteColor
                                            ? {
                                                  color: '#F7F8F5',
                                              }
                                            : {
                                                  map: texture,
                                              }
                                    );

                                const substrateMaterial = isExteriorMaterial
                                    ? shouldApplyEdging
                                        ? edgeMaterial
                                        : exteriorSubstrateMaterial
                                    : plainWhiteColor
                                    ? includeHardware
                                        ? new THREE.MeshStandardMaterial({
                                              color: '#F7F8F5',
                                          })
                                        : null
                                    : carcaseSubstrateMaterial;

                                const computedExteriorSubstrateMaterial =
                                    shouldApplyEdging
                                        ? edgeMaterial
                                        : exteriorSubstrateMaterial;
                                const topSubstrateMaterial =
                                    substrateMaterial?.clone();

                                if (hideSgement) {
                                    reduceMaterialOpacity(
                                        faceMaterial,
                                        partiallyTransparentSegment
                                            ? 0.8
                                            : undefined
                                    );
                                    reduceMaterialOpacity(
                                        edgeMaterial,
                                        partiallyTransparentSegment
                                            ? 0.8
                                            : undefined
                                    );
                                    reduceMaterialOpacity(
                                        topSubstrateMaterial,
                                        partiallyTransparentSegment
                                            ? 0.8
                                            : undefined
                                    );
                                }

                                if (nonSupplyCarcase) {
                                    reduceMaterialOpacity(faceMaterial);
                                    reduceMaterialOpacity(edgeMaterial);
                                    reduceMaterialOpacity(topSubstrateMaterial);
                                }

                                let edgingLocal = {...edging};

                                if (operationCount > 3) {
                                    edgingLocal = null;
                                }

                                if (dynamicExteriorEdging) {
                                    edgingLocal = {...edging};
                                }

                                // For top substrate
                                let topColor = edgingLocal
                                    ? edgingLocal.w1
                                        ? edgeMaterial
                                        : isExteriorMaterial
                                        ? computedExteriorSubstrateMaterial
                                        : substrateMaterial
                                    : // Edges the right side of the front vent panel in rangehood
                                    isFrontRangehoodVent
                                    ? edgeMaterial
                                    : topSubstrateMaterial;

                                if (withTopEdging) {
                                    topColor = edgeMaterial;
                                }

                                // For bottom substrate
                                const bottomColor = isEdgedBottom
                                    ? edgeMaterial
                                    : edgingLocal
                                    ? edgingLocal.w2
                                        ? edgeMaterial
                                        : isExteriorMaterial
                                        ? computedExteriorSubstrateMaterial
                                        : substrateMaterial
                                    : substrateMaterial;

                                // For front substrate
                                let frontColor = noEdging
                                    ? substrateMaterial
                                    : edgingLocal
                                    ? edgingLocal.l1
                                        ? edgeMaterial
                                        : isExteriorMaterial
                                        ? computedExteriorSubstrateMaterial
                                        : substrateMaterial
                                    : edgeMaterial;

                                if (withFrontEdging) {
                                    frontColor = edgeMaterial;
                                }

                                // For back substrate
                                const backColor = edgingLocal
                                    ? edgingLocal.l2
                                        ? edgeMaterial
                                        : isExteriorMaterial
                                        ? computedExteriorSubstrateMaterial
                                        : substrateMaterial
                                    : substrateMaterial;

                                material = [
                                    topColor,
                                    bottomColor,
                                    flipSubstrate ? backColor : frontColor,
                                    flipSubstrate ? frontColor : backColor,
                                    faceMaterial,
                                    faceMaterial,
                                ];
                            }

                            const mesh = new THREE.Mesh(boxGeometry, material);

                            if (shelfLabel && showShelfLabel) {
                                addLabelMesh(
                                    shelfLabel,
                                    size,
                                    thickness.carcaseThickness,
                                    lineMesh,
                                    isLeftReturnProduct,
                                    isRightReturnProduct
                                );
                            }

                            mesh.position.copy(size);
                            panelGroup.add(mesh);

                            segmentMaterial.visible = true;
                        })();
                    },
                    undefined,
                    () => {
                        const defaultMaterial = new THREE.MeshStandardMaterial({
                            color: '#fff',
                        });

                        const mesh = new THREE.Mesh(
                            boxGeometry,
                            defaultMaterial
                        );
                        mesh.position.copy(size);
                        panelGroup.add(mesh);
                        segmentMaterial.visible = true;
                    }
                );
            } else {
                segmentMaterial.visible = true;
            }

            const lineMesh = new THREE.LineSegments(geometry, segmentMaterial);

            if (!showTexture) {
                if (shelfLabel && showShelfLabel) {
                    const extrudeGeometryForBox = new THREE.ExtrudeGeometry(
                        shape,
                        {
                            ...extrudeSettings,
                        }
                    );
                    const box = new THREE.Box3().setFromObject(
                        new THREE.Mesh(extrudeGeometryForBox)
                    );
                    const size = new THREE.Vector3();
                    box.getSize(size);
                    box.getCenter(size);

                    addLabelMesh(
                        shelfLabel,
                        size,
                        thickness.carcaseThickness,
                        lineMesh,
                        isLeftReturnProduct,
                        isRightReturnProduct
                    );
                }
            }

            if (hideSgement) {
                reduceMaterialOpacity(
                    lineMesh.material,
                    partiallyTransparentSegment ? 0.8 : undefined
                );
            }

            if (nonSupplyCarcase) {
                reduceMaterialOpacity(lineMesh.material);
            }

            panelGroup.add(lineMesh);
        }
    }

    panelGroup.rotateZ(Math.PI / 2);

    if (operationsGroup.MfgOrientationID === undefined) {
        panelGroup.scale.setY(-1);
    }

    panelGroup.scale.setZ(-1);

    const ret = new THREE.Group();
    ret.add(panelGroup);

    return ret;
};

const disposeMesh = (mesh: THREE.Mesh): void => {
    if (mesh.geometry) {
        mesh.geometry.dispose();
    }

    if (mesh.material) {
        const materials = Array.isArray(mesh.material)
            ? mesh.material
            : [mesh.material];
        materials.forEach(
            (material: THREE.Material & {map: {dispose: () => void}}) => {
                if (material.map) material.map.dispose();

                material.dispose();
            }
        );
    }
};

const disposeThreeObject = (object: THREE.Object3D): void => {
    if (!object) return;

    object?.traverse((child: THREE.Object3D) => {
        if ((child as THREE.Mesh).isMesh) {
            disposeMesh(child as THREE.Mesh);
        }
    });

    if (object.parent) {
        object.parent.remove(object);
    }
};

export const disposeThreeScene = (
    scene: THREE.Scene,
    renderer: THREE.WebGLRenderer
): void => {
    if (scene) {
        scene?.traverse((object: THREE.Object3D) => {
            disposeThreeObject(object);
        });

        scene.clear();
    }

    if (renderer) {
        renderer.dispose();
    }
};
