import {useCallback} from 'react';
import {useProductContext} from 'contexts';
import {
    TemplateVariable,
    ProductDataStore,
} from 'components/customer/Preview3D/usePreview3DData';
import {ConditionalValues} from 'Preview3D/types';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

type FormValues = {
    height: number;
    depth: number;
    width: number;
    rangehoodOpeningHeight: number;
};

export const DEFAULT_VENT_WIDTH = 176.75;
export const DEFAULT_VENT_FRONT_WIDTH = 203;

const useRangehoodDynamicValues = () => {
    const {values} = usePreviewFormValues();
    const {carcaseThickness} = useMaterialTexture();

    const formValues: FormValues = {
        height: Number(values.cabinet_height),
        depth: Number(values.cabinet_depth),
        width: Number(values.cabinet_width),
        rangehoodOpeningHeight: Number(values.rangehood_opening_height),
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {productDataStore} = useProductContext() as {
        productDataStore: ProductDataStore;
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const generateRangehoodValues = useCallback(() => {
        return {
            verticalSetback: formValues.rangehoodOpeningHeight,
            ventPositionZ: formValues.rangehoodOpeningHeight,
            ventWidth: DEFAULT_VENT_WIDTH,
            ventHeight: formValues.height - formValues.rangehoodOpeningHeight,
            ventRightPositionX:
                formValues.width / 2 + DEFAULT_VENT_FRONT_WIDTH / 2,
            ventSidePositionY: formValues.depth - carcaseThickness,
            ventLeftPositionX:
                formValues.width / 2 - DEFAULT_VENT_FRONT_WIDTH / 2,
            ventFrontPositionY:
                formValues.depth - DEFAULT_VENT_WIDTH - carcaseThickness,
        };
    }, [formValues]);

    const rangehoodValues: ConditionalValues[] = [
        {
            key: 'rangehood',
            condition:
                variables?.hasRangehoodVent ||
                typeof values.rangehood_opening_height !== 'undefined',
            callback: generateRangehoodValues,
        },
    ];

    return {
        rangehoodValues,
    };
};

export default useRangehoodDynamicValues;
