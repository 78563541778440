import {useFormikContext} from 'formik';
import {PreviewFormValues} from 'Preview3D/types';

const usePreviewFormValues = () => {
    const {values} = useFormikContext<PreviewFormValues>();

    return {
        values,
    };
};

export default usePreviewFormValues;
