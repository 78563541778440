import React from 'react';
import styled from 'styled-components';

const Preview3DNote = () => {
    return (
        <Note>
            <strong>Note: </strong> The 3D preview is for demonstration purposes
            only, and the final product composition may change at manufacture.
        </Note>
    );
};

const Note = styled.div`
    font-size: 13px;
    font-style: italic;
    margin-top: 10px;
    color: #544343;
    box-shadow: none;
`;

export default Preview3DNote;
