import {useEffect, useMemo} from 'react';
import * as THREE from 'three';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {MaterialType} from 'components/customer/Materials/entity';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';
import {
    getTextures,
    setExteriorTexture,
    setExteriorEdgeTexture,
    setCarcaseTexture,
    setCarcaseEdgeTexture,
    setTexturePath,
    getTexturePaths,
} from 'components/customer/Preview3DCommon/store/viewerSlice';

const SUBSTRATE_MAPPING: {[key: string]: string[]} = {
    MDF: [
        'MR MDF',
        'MDF',
        'E0 MR MDF',
        'MRMDF',
        'E0 LDF',
        'LDF',
        'E0 MR LDF',
        'MDF HP',
        'E0 MR MDF HD',
        'Tricoya',
        'WCC',
        'E0 MR MDF LMG',
        'FR MDF',
        'Extreme Durable MDF',
        ' MDF ',
    ],
    Black: ['CL'],
    ParticleBoard: ['HMR PB', 'STD PB', 'E0 HMR PB', 'MR PB', 'PB'],
    Plywood: [
        'Ply',
        'Birch Ply',
        'Falcata Plywood',
        'Birch Plywood',
        'Plywood',
        'AB Ply',
        'AA Ply',
        'BB Ply',
    ],
    NonSupply: ['Customer Supply', 'Na'],
    White: ['Expandable Foam', 'FOAM', 'PVC', 'PVC 2Pac'],
};

const useMaterialTexture = () => {
    const dispatch = useAppDispatch();

    const selectedExteriorMaterial = useAppSelector(
        (state) => getMaterial(state, MaterialType.EXTERIOR),
        shallowEqual
    );
    const selectedCarcaseMaterial = useAppSelector(
        (state) => getMaterial(state, MaterialType.CARCASE),
        shallowEqual
    );
    const selectedExteriorMaterialEdge = useAppSelector(
        (state) => getEdge(state, MaterialType.EXTERIOR),
        shallowEqual
    );
    const selectedCarcaseMaterialEdge = useAppSelector(
        (state) => getEdge(state, MaterialType.CARCASE),
        shallowEqual
    );
    const texturePaths = useAppSelector(getTexturePaths, shallowEqual);

    const {
        exteriorTexture,
        exteriorEdgeTexture,
        carcaseTexture,
        carcaseEdgeTexture,
    } = useAppSelector(getTextures, shallowEqual);

    const loadTexture = (
        imagePath: string,
        successHandler?: (data: THREE.Texture) => void
    ) => {
        const textureLoader = new THREE.TextureLoader();

        return textureLoader.load(imagePath, successHandler);
    };

    useEffect(() => {
        if (selectedExteriorMaterial?.image) {
            dispatch(
                setTexturePath({
                    type: 'EXTERIOR',
                    path: selectedExteriorMaterial?.image,
                })
            );

            if (texturePaths.exterior) {
                const texture = loadTexture(selectedExteriorMaterial.image);
                dispatch(setExteriorTexture(texture));
            }
        }
    }, [selectedExteriorMaterial?.image, texturePaths.exterior]);

    useEffect(() => {
        if (selectedCarcaseMaterial?.image) {
            const texture = loadTexture(selectedCarcaseMaterial.image);
            dispatch(setCarcaseTexture(texture));
        }
    }, [selectedCarcaseMaterial?.image]);

    useEffect(() => {
        if (selectedExteriorMaterialEdge?.image) {
            const texture = loadTexture(selectedExteriorMaterialEdge.image);
            dispatch(setExteriorEdgeTexture(texture));
        }
    }, [selectedExteriorMaterialEdge?.image]);

    useEffect(() => {
        if (selectedCarcaseMaterialEdge?.image) {
            const texture = loadTexture(selectedCarcaseMaterialEdge.image);
            dispatch(setCarcaseEdgeTexture(texture));
        }
    }, [selectedCarcaseMaterialEdge?.image]);

    const getSubstrateMaterial = (substrate: string) => {
        const selectedSubstrate = Object.keys(SUBSTRATE_MAPPING)
            .map((key) => {
                const filteredSubstrate = SUBSTRATE_MAPPING[String(key)].find(
                    (s: string) => s == substrate
                );

                if (filteredSubstrate) return key;
            })
            .filter((s) => s);

        if (
            selectedSubstrate &&
            selectedSubstrate.length > 0 &&
            selectedSubstrate[0]
        ) {
            const sub = selectedSubstrate[0];
            const textureLoader = new THREE.TextureLoader();
            let material = null;
            let texture: THREE.Texture = null;

            switch (sub) {
                case 'White':
                    material = new THREE.MeshStandardMaterial({
                        color: '#fff',
                    });
                    break;
                case 'ParticleBoard':
                    texture = textureLoader.load(
                        `/templates/3D/textures/ParticleBoard.jpg`
                    );
                    break;
                case 'Plywood':
                    texture = textureLoader.load(
                        `/templates/3D/textures/Plywood.jpg`
                    );
                    break;
                case 'NonSupply':
                    texture = textureLoader.load(
                        `/uploads/gocabinet_materials/Non%20Supply/Na.jpg`
                    );
                    break;
                case 'Black':
                    material = new THREE.MeshStandardMaterial({
                        color: '#333',
                    });
                    break;
                default:
                    texture = textureLoader.load(
                        `/templates/3D/textures/MDF.jpg`
                    );
                    break;
            }

            if (texture) {
                texture.wrapS = THREE.RepeatWrapping;
                texture.wrapT = THREE.RepeatWrapping;
                texture.repeat.set(4, 2);

                material = new THREE.MeshStandardMaterial({
                    map: texture,
                });
            }

            return material;
        }
    };

    const carcaseSubstrateMaterial = useMemo(() => {
        if (!selectedCarcaseMaterial?.substrate) return null;
        const substrate = selectedCarcaseMaterial?.substrate;

        return getSubstrateMaterial(substrate);
    }, [selectedCarcaseMaterial?.substrate]);

    const exteriorSubstrateMaterial = useMemo(() => {
        if (!selectedExteriorMaterial?.substrate) return null;
        const substrate = selectedExteriorMaterial?.substrate;

        return getSubstrateMaterial(substrate);
    }, [selectedExteriorMaterial?.substrate]);

    return {
        exteriorTexture,
        exteriorEdgeTexture,
        carcaseTexture,
        carcaseEdgeTexture,
        exteriorThickness: selectedExteriorMaterial?.thickness || 16.5,
        carcaseThickness: selectedCarcaseMaterial?.thickness || 16.5,
        carcaseSubstrateMaterial,
        exteriorSubstrateMaterial,
    };
};

export default useMaterialTexture;
