import {useCallback} from 'react';
import {useProductContext} from 'contexts';
import {
    TemplateVariable,
    ProductDataStore,
} from 'components/customer/Preview3D/usePreview3DData';
import {ConditionalValues} from 'Preview3D/types';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';
import {makeZeroIfUndefined} from 'components/customer/Preview3D/helpers';

type FormValues = {
    height: number;
    depth: number;
    width: number;
    ovenOpeningHeight: number;
    topSectionHeight: number;
    totalDrawerHeight: number;
    microwaveOpeningHeight: number;
};

export const DEFAULT_UPPER_PAIR_DEPTH = 400;

const useWallOvenDynamicValues = () => {
    const {values} = usePreviewFormValues();
    const {exteriorThickness} = useMaterialTexture();

    const formValues: FormValues = {
        height: Number(values.cabinet_height),
        depth: Number(values.cabinet_depth),
        width: Number(values.cabinet_width),
        ovenOpeningHeight: Number(values.oven_opening_height),
        topSectionHeight:
            makeZeroIfUndefined(values.cabinet_height) -
            makeZeroIfUndefined(values.cabinet_total_drawer_height) -
            makeZeroIfUndefined(values.oven_opening_height) -
            makeZeroIfUndefined(values.microwave_opening_height),
        totalDrawerHeight:
            makeZeroIfUndefined(values.cabinet_total_drawer_height) +
            makeZeroIfUndefined(values.oven_opening_height),
        microwaveOpeningHeight: makeZeroIfUndefined(
            values.microwave_opening_height
        ),
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {productDataStore} = useProductContext() as {
        productDataStore: ProductDataStore;
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );

    const gemerateWallOvenValues = useCallback(() => {
        return {
            baseHeight: formValues.totalDrawerHeight,
            hasMicrowave: formValues.microwaveOpeningHeight > 0,
            upperPair: {
                basePositionZ:
                    Number(values.cabinet_height) - formValues.topSectionHeight,
                height: formValues.topSectionHeight,
                depth:
                    formValues.depth >= DEFAULT_UPPER_PAIR_DEPTH
                        ? DEFAULT_UPPER_PAIR_DEPTH
                        : formValues.depth,
            },
            microwave: {
                topHeight:
                    Number(values.cabinet_height) -
                    formValues.topSectionHeight -
                    exteriorThickness,
                bottomHeight:
                    Number(values.cabinet_height) -
                    formValues.topSectionHeight -
                    formValues.microwaveOpeningHeight -
                    exteriorThickness,
            },
        };
    }, [formValues]);

    const wallOvenValues: ConditionalValues[] = [
        {
            key: 'wallOven',
            condition: variables?.isWallOvenProduct,
            callback: gemerateWallOvenValues,
        },
    ];

    return {
        wallOvenValues,
    };
};

export default useWallOvenDynamicValues;
