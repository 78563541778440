import {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {useDebouncedCallback} from 'use-debounce';
import {xml2json, json2xml} from 'xml-js';
import {
    dataSet,
    templateSet,
    selectData,
    selectTemplate,
    clearPreview,
    reset,
    setImageDataUrl,
} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {shallowEqual} from 'react-redux';
import usePreview3DData, {
    ProductDataStore,
} from 'components/customer/Preview3D/usePreview3DData';
import usePreview3D from 'components/customer/Preview3D/usePreview3D';
import {PreviewPosition, Batch, PreviewLocation} from 'Preview3D/types';
import useDoorAndDrawerAnimation from 'components/customer/Preview3D/animation/useDoorAndDrawerAnimation';
import axios from 'axios';
import {useProductContext} from 'contexts';
import useCameraSetup from 'components/customer/Preview3D/lib/useCameraSetup';
import useLightingSetup from 'components/customer/Preview3D/lib/useLightingSetup';
import useRendererSetup from 'components/customer/Preview3D/lib/useRendererSetup';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

const useThree = (
    previewLocation: PreviewLocation,
    expanded?: boolean,
    hidden?: boolean,
    removeDebounce?: boolean
) => {
    const dispatch = useAppDispatch();
    const previewData = useAppSelector(selectData, shallowEqual);
    const template = useAppSelector(selectTemplate, shallowEqual);
    const previewRef = useRef<HTMLDivElement>();
    const scene = useRef<THREE.Scene>();
    const initialized = useRef(false);
    const loaded = useRef(false);

    const [isLoading, setIsLoading] = useState(false);
    const [templateFetched, setTemplateFetched] = useState(false);
    const [showTexture, setShowTexture] = useState(true);

    const {setupLighting} = useLightingSetup(scene);
    const {renderer, setupRenderer} = useRendererSetup(previewRef);
    const {
        camera,
        setupCamera,
        setupCameraPosition,
        controls,
        setupOrbitControls,
    } = useCameraSetup(previewRef, renderer, expanded);

    const {
        setButtonLabel,
        parseButtonLabel,
        animationHandler,
        openDoorButtonRef,
        clearHandlers,
        toggleInnerDrawerHandler,
        isDoorOpenRef,
    } = useDoorAndDrawerAnimation(
        ['DOORS', 'DRAWERS'].includes(previewLocation) ? false : true
    );

    const {
        fieldDependencies,
        replaceDimensions,
        templateData,
        setupHardware,
        variables,
        hasError,
        errors,
        getShapedPanels,
    } = usePreview3DData(
        scene,
        toggleInnerDrawerHandler,
        showTexture,
        previewLocation
    );
    const showLeftIsoView = variables?.isLeftClip || variables?.isLeftRadius;
    const [previewPosition, setPreviewPosition] = useState<PreviewPosition>(
        previewLocation === 'DOORS'
            ? 'FRONT'
            : showLeftIsoView
            ? 'LEFT'
            : 'RIGHT'
    );

    const {loadDataXML, createSceneXML} = usePreview3D();
    const {
        exteriorTexture,
        exteriorEdgeTexture,
        carcaseTexture,
        carcaseEdgeTexture,
    } = useMaterialTexture();

    const {values} = usePreviewFormValues();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const {adjustableLegsQuantity, productDataStore} = useProductContext() as {
        adjustableLegsQuantity: number;
        productDataStore: ProductDataStore;
    };

    const createScene = useCallback(
        (batch: Batch) => {
            scene.current = new THREE.Scene();

            createSceneXML(
                batch,
                scene.current,
                animationHandler,
                showTexture,
                typeof values.include_drawer_faces !== 'undefined'
                    ? Boolean(values.include_drawer_faces)
                    : true,
                previewLocation !== 'DRAWERS',
                previewLocation === 'SHELVES',
                previewLocation,
                getShapedPanels
            );
        },
        [...fieldDependencies, showTexture, getShapedPanels]
    );

    const loadXml = useDebouncedCallback(
        async () => {
            if (templateData) {
                dispatch(clearPreview());

                const response = await axios.get(
                    `/templates/3D/${templateData}`
                );
                const xmlContent = productDataStore.current?.template_3d[1];
                const json = xml2json((xmlContent || response.data) as string, {
                    compact: false,
                });
                dispatch(templateSet(json));
                setTemplateFetched(true);
                reloadScene();
            }
        },
        removeDebounce ? 0 : 800
    );

    const reloadScene = useDebouncedCallback(
        () => {
            if (template) {
                const parsedJson = replaceDimensions(template);
                const data = loadDataXML(
                    json2xml(parsedJson, {compact: false})
                );

                clearHandlers();
                setButtonLabel('Open');

                dispatch(dataSet(data));
            }
        },
        removeDebounce ? 0 : 300
    );

    const loadPreview3D = useDebouncedCallback(
        useCallback(
            (data: Batch) => {
                if (previewRef.current) {
                    createScene(data);
                    setupLighting();
                    setupHardware();
                    setIsLoading(false);
                }
            },
            [
                ...fieldDependencies,
                adjustableLegsQuantity,
                showTexture,
                carcaseTexture,
                carcaseEdgeTexture,
                exteriorTexture,
                exteriorEdgeTexture,
                values.cabinet_include_hardware,
            ]
        ),
        removeDebounce ? 0 : 800
    );

    const initialize = useCallback(() => {
        if (previewRef.current) {
            setupCamera();
            setupCameraPosition(previewPosition);

            scene.current = new THREE.Scene();

            const rendererLocal = setupRenderer();
            previewRef.current.appendChild(rendererLocal.current.domElement);

            setupOrbitControls();
        }
    }, []);

    const animate = useCallback(() => {
        requestAnimationFrame(animate);
        controls.current.update();

        renderer.current.clear();
        renderer.current.clearDepth();
        renderer.current.render(scene.current, camera.current);

        TWEEN.update();
    }, []);

    useEffect(() => {
        return () => {
            dispatch(reset());
            renderer.current?.dispose();
        };
    }, []);

    useEffect(() => {
        if (previewData != null && templateFetched && !hasError.current) {
            setIsLoading(true);
            loaded.current = true;

            loadPreview3D(previewData as Batch);
        }

        if (hasError.current && !loaded.current) {
            loadPreview3D(previewData as Batch);
        }
    }, [previewData, templateFetched, errors]);

    useEffect(() => {
        if (previewData != null) {
            reloadScene();
        }
    }, [...fieldDependencies]);

    const setImageDataUrlTrigger = useDebouncedCallback(
        () => {
            const screenshotDataUrl =
                renderer.current.domElement.toDataURL('image/png');

            dispatch(setImageDataUrl(screenshotDataUrl));
        },
        removeDebounce ? 0 : 300
    );

    useEffect(() => {
        if (hidden) {
            setImageDataUrlTrigger();
        }
    });

    const handlePreviewRef = useCallback((ref: HTMLDivElement) => {
        previewRef.current = ref;

        if (
            previewRef?.current &&
            previewRef.current.clientHeight !== 0 &&
            !initialized.current
        ) {
            setIsLoading(true);

            void loadXml();
            void initialize();
            void animate();

            initialized.current = true;
        }
    }, []);

    const toggleTexture = useCallback(() => {
        setShowTexture(!showTexture);
    }, [showTexture]);

    return {
        previewRef,
        setupCameraPosition,
        reloadScene,
        parseButtonLabel,
        previewPosition,
        setPreviewPosition,
        showTexture,
        isDoorOpenRef,
        openDoorButtonRef,
        variables,
        isLoading,
        handlePreviewRef,
        toggleTexture,
        renderer,
    };
};

export default useThree;
