import React, {useCallback, useEffect, useState} from 'react';
import {Image} from 'react-bootstrap';
import {useAppSelector, useAppDispatch} from 'store/customer';
import styled, {useTheme} from 'styled-components';
import {PreviewPosition, PreviewLocation} from 'Preview3D/types';
import {
    PerspectiveRight,
    DrawerClosed,
    DrawerOpen,
    IsometricLeft,
    IsometricRight,
    PerspectiveFront,
    PerspectiveLeft,
    Solid,
    Wireframe,
    Expand,
    Collapse,
} from 'assets';
import {
    getIsPreviewHighlighted,
    setIsPreviewHighlighted,
    setPreviewHighlightUpdateFreeze,
    getShowExpandedView,
    setShowExpandedView,
} from 'components/customer/Preview3DCommon/store/viewerSlice';
import ExpandedView from 'components/customer/Preview3D/components/ExpandedView';
import {debounce} from 'lodash';
import {getColoredImage} from 'shared/helpers';
import TWEEN from '@tweenjs/tween.js';
import {OverlayTrigger} from 'shared/components/OverlayTrigger';

interface PreviewControlProps {
    previewPosition: PreviewPosition;
    setPreviewPosition: React.Dispatch<React.SetStateAction<PreviewPosition>>;
    onPreviewPositionChanged: (previewPosition: PreviewPosition) => void;
    toggleLabel: string;
    onTogglePreview: React.MouseEventHandler<HTMLImageElement>;
    toggleDoorRef: React.MutableRefObject<HTMLImageElement>;
    showToggle: boolean;
    showTexture: boolean;
    isDoorOpen: boolean;
    previewLocation: PreviewLocation;
    expanded?: boolean;
    hideExpandIcon?: boolean;
}

type Option = {
    title: string;
    imgSrc: string;
    selected: boolean;
    defaultColor: string;
    clickHandler: React.MouseEventHandler<HTMLImageElement>;
};

const PreviewControl = ({
    previewPosition,
    setPreviewPosition,
    onPreviewPositionChanged,
    toggleLabel,
    onTogglePreview,
    toggleDoorRef,
    showToggle,
    showTexture,
    isDoorOpen,
    previewLocation,
    expanded,
    hideExpandIcon,
}: PreviewControlProps) => {
    const dispatch = useAppDispatch();
    const isPreviewHighlighted = useAppSelector(getIsPreviewHighlighted);
    const showExpandedView = useAppSelector(getShowExpandedView);
    const theme = useTheme();

    const primaryColour = theme.colors.primary.main;
    const secondaryColour = theme.colors.secondary.main;

    const [isAnimating, setIsAnimating] = useState(false);

    const handlePreviewHighlightUpdateFreeze = debounce(() => {
        dispatch(setPreviewHighlightUpdateFreeze(false));
    }, 1000);

    const changePreviewPosition = (position: PreviewPosition) => {
        setPreviewPosition(position);
        localStorage.setItem('PREVIEW_POSITION', position);
        onPreviewPositionChanged(position);

        dispatch(setIsPreviewHighlighted(true));
        dispatch(setPreviewHighlightUpdateFreeze(true));

        handlePreviewHighlightUpdateFreeze();
    };

    const setShowExpandedViewCallback = useCallback(
        (show: boolean) => {
            dispatch(setShowExpandedView({type: previewLocation, value: show}));
        },
        [previewLocation]
    );

    const expandedViewHandler = useCallback(() => {
        dispatch(
            setShowExpandedView({
                type: previewLocation,
                value: !showExpandedView[`${previewLocation}`],
            })
        );
    }, [showExpandedView, previewLocation]);

    const changePreviewPositionToLeft = useCallback(() => {
        changePreviewPosition('LEFT');
    }, []);
    const changePreviewPositionToRight = useCallback(() => {
        changePreviewPosition('RIGHT');
    }, []);
    const changePreviewPositionToLeftLeveled = useCallback(() => {
        changePreviewPosition('LEFT_LEVELED');
    }, []);
    const changePreviewPositionToFront = useCallback(() => {
        changePreviewPosition('FRONT');
    }, []);
    const changePreviewPositionToRightLeveled = useCallback(() => {
        changePreviewPosition('RIGHT_LEVELED');
    }, []);

    useEffect(() => {
        // Check every 100ms if there are active tweens
        const interval = setInterval(() => {
            const activeAnimations = TWEEN.getAll().length > 0;
            setIsAnimating(activeAnimations);
        }, 100);

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const options: Option[] = [
        {
            title: 'Left View',
            imgSrc: PerspectiveLeft,
            selected: previewPosition === 'LEFT_LEVELED',
            defaultColor: '#1591cc',
            clickHandler: changePreviewPositionToLeftLeveled,
        },
        {
            title: 'Right View',
            imgSrc: PerspectiveRight,
            selected: previewPosition === 'RIGHT_LEVELED',
            defaultColor: '#1591cc',
            clickHandler: changePreviewPositionToRightLeveled,
        },
        {
            title: 'Front View',
            imgSrc: PerspectiveFront,
            selected: previewPosition === 'FRONT',
            defaultColor: '#1392cd',
            clickHandler: changePreviewPositionToFront,
        },

        {
            title: 'Top Left View',
            imgSrc: IsometricLeft,
            selected: previewPosition === 'LEFT',
            defaultColor: '#1591cc',
            clickHandler: changePreviewPositionToLeft,
        },
        {
            title: 'Top Right View',
            imgSrc: IsometricRight,
            selected: previewPosition === 'RIGHT',
            defaultColor: '#1591cc',
            clickHandler: changePreviewPositionToRight,
        },
    ];

    return (
        <ViewOptionContainer>
            {showToggle ? (
                <OverlayTrigger
                    ref={toggleDoorRef}
                    placement="bottom"
                    overlay={<strong>{toggleLabel}</strong>}>
                    <StyledImageAction
                        className="icon_button"
                        $expanded={expanded}
                        style={{
                            backgroundColor: 'transparent',
                            pointerEvents: isAnimating ? 'none' : undefined,
                        }}
                        src={getColoredImage(
                            isDoorOpen ? DrawerClosed : DrawerOpen,
                            '#204480',
                            primaryColour
                        )}
                    />
                </OverlayTrigger>
            ) : null}

            <OverlayTrigger
                placement="bottom"
                overlay={
                    <strong>
                        {showTexture
                            ? 'Switch to Wireframe'
                            : 'Switch to Textured'}
                    </strong>
                }>
                <StyledImageAction
                    className="icon_button"
                    style={{
                        backgroundColor: 'transparent',
                    }}
                    $expanded={expanded}
                    src={getColoredImage(
                        showTexture ? Wireframe : Solid,
                        '#204480',
                        primaryColour
                    )}
                    onClick={onTogglePreview}
                />
            </OverlayTrigger>

            {!hideExpandIcon ? (
                !expanded ? (
                    <>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <strong>Expand Product Visualiser</strong>
                            }>
                            <StyledImageAction
                                className="icon_button"
                                style={{
                                    backgroundColor: 'transparent',
                                }}
                                src={getColoredImage(
                                    Expand,
                                    '#204380',
                                    primaryColour
                                )}
                                onClick={expandedViewHandler}
                            />
                        </OverlayTrigger>
                        <ExpandedView
                            previewLocation={previewLocation}
                            open={showExpandedView[`${previewLocation}`]}
                            setShowExpandedView={setShowExpandedViewCallback}
                        />
                    </>
                ) : (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<strong>Close Product Visualiser</strong>}>
                        <StyledImageAction
                            className="icon_button"
                            style={{
                                backgroundColor: 'transparent',
                            }}
                            src={getColoredImage(
                                Collapse,
                                '#005b7f',
                                primaryColour
                            )}
                            onClick={expandedViewHandler}
                        />
                    </OverlayTrigger>
                )
            ) : null}

            <Divider />

            {options.map((option) => (
                <OverlayTrigger
                    key={option.title}
                    placement="bottom"
                    overlay={<strong>{option.title}</strong>}>
                    <StyledImage
                        className="icon_button"
                        $selected={
                            option.selected && isPreviewHighlighted
                                ? true
                                : false
                        }
                        $expanded={expanded}
                        onClick={option.clickHandler}
                        src={getColoredImage(
                            option.imgSrc,
                            option.defaultColor,
                            secondaryColour
                        )}
                    />
                </OverlayTrigger>
            ))}
        </ViewOptionContainer>
    );
};

const ViewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    min-width: 50px;
    border-radius: 0 8px 8px 0;
    background: #eee;
    flex-direction: column;
    height: 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

const StyledImage = styled(Image)<{$selected?: boolean; $expanded?: boolean}>`
    flex: 1;
    fill: red;
    max-height: ${({$expanded}) => ($expanded ? '45px' : '34px')};
    cursor: pointer;
    margin: 5px;
    border: 2px solid transparent;
    border-radius: 4px;
    ${({$selected}) =>
        $selected ? 'border-color: rgb(var(--secondary_colour));' : ''}
`;

const StyledImageAction = styled(Image)<{$expanded?: boolean}>`
    max-height: ${({$expanded}) => ($expanded ? '50px' : '36px')};
    cursor: pointer;
    border: 2px solid transparent;
    margin: 2px;
`;

const Divider = styled.div`
    width: 100%;
    height: 2px;
    margin: 7px 5px;
    background-color: #bdbdbd;
`;

export default PreviewControl;
