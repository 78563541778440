import {useCallback} from 'react';
import {ConditionalValues} from 'Preview3D/types';
import {makeZeroIfUndefined} from 'components/customer/Preview3D/helpers';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import usePreviewFormValues from 'components/customer/Preview3D/lib/usePreviewFormValues';

type FormValues = {
    height: number;
    width: number;
    microwaveOpeningHeight: number;
    shelvesHeightReference: ShelvesTypeReference;
    shelvesHeightReference3Part: ShelvesTypeReference3Part;
};

type ShelvesTypeReference = {
    upper_shelves: number;
    lower_shelves: number;
};

type ShelvesTypeReference3Part = ShelvesTypeReference & {
    middle_shelves: number;
};

const useMultiPartDynamicValues = () => {
    const {values} = usePreviewFormValues();
    const {carcaseThickness, exteriorThickness} = useMaterialTexture();

    const cabinetUpperPartitionHeight =
        Number(values.cabinet_upper_partition_height) > 1
            ? Number(values.cabinet_upper_partition_height) /
              Number(values.cabinet_height)
            : Number(values.cabinet_upper_partition_height);
    const cabinetLowerPartitionHeight =
        Number(values.cabinet_lower_partition_height) > 1
            ? Number(values.cabinet_lower_partition_height) /
              Number(values.cabinet_height)
            : Number(values.cabinet_lower_partition_height);

    const upperShelfOffset =
        cabinetUpperPartitionHeight * Number(values.cabinet_height);
    const lowerShelfOffset =
        cabinetLowerPartitionHeight * Number(values.cabinet_height);

    const formValues: FormValues = {
        height: makeZeroIfUndefined(values.cabinet_height),
        width: makeZeroIfUndefined(values.cabinet_width),
        microwaveOpeningHeight: makeZeroIfUndefined(
            values.microwave_opening_height
        ),
        shelvesHeightReference: {
            upper_shelves:
                Number(values.cabinet_height) -
                Number(values.cabinet_partition_height),
            lower_shelves: Number(values.cabinet_partition_height),
        },
        shelvesHeightReference3Part: {
            upper_shelves: Number(values.cabinet_height) - upperShelfOffset,
            middle_shelves: upperShelfOffset - lowerShelfOffset,
            lower_shelves: lowerShelfOffset,
        },
    };

    const generateMultiPart = useCallback(() => {
        const thicknessDiff = exteriorThickness - carcaseThickness;

        return {
            microwave: {
                bottomHeight:
                    formValues.height - formValues.microwaveOpeningHeight,
                topHeight: formValues.microwaveOpeningHeight,
                topWidth: formValues.width - thicknessDiff,
                thicknessDiff,
            },
            pantry: {
                bottomHeight: formValues.shelvesHeightReference.lower_shelves,
                topHeight: formValues.shelvesHeightReference.upper_shelves,
                bottomBaseTopPosition:
                    formValues.shelvesHeightReference.lower_shelves -
                    carcaseThickness,
                upperSidePanelPosition:
                    formValues.shelvesHeightReference.lower_shelves,
            },
            cornerPantry: {
                bottomHeight:
                    formValues.shelvesHeightReference3Part.lower_shelves,
                topHeight: formValues.shelvesHeightReference3Part.upper_shelves,
                middleHeight:
                    formValues.shelvesHeightReference3Part.middle_shelves,
                bottomBaseTopPosition:
                    formValues.shelvesHeightReference3Part.lower_shelves -
                    carcaseThickness,
                upperSidePanelPosition: upperShelfOffset,
                middleSidePanelPosition: lowerShelfOffset,
            },
        };
    }, [formValues]);

    const multiPartValues: ConditionalValues[] = [
        {
            key: 'multiPart',
            condition:
                Boolean(formValues.microwaveOpeningHeight) ||
                Boolean(values?.upper_shelves && values?.lower_shelves),
            callback: generateMultiPart,
        },
    ];

    return {
        multiPartValues,
    };
};

export default useMultiPartDynamicValues;
